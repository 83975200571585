import {
  add,
  filter,
  find,
  get,
  isBoolean,
  max,
  min,
  multiply,
  sortBy,
} from 'lodash';

import { FORM, POINT_TYPE } from '../constants';
import { ShipmentHelpers } from '../helpers';

// NOTE: Not removed yet because logic is not fully finalized
/**
 *
 * @param {Array} networks - Array of networks
 * @param {boolean} dropAtShop - Bollean indicator of whether parcel goes to shop
 * @returns {number} - Minimum price based on parcel destination and networks
 */
export const getMinPriceFromNetworksByDropAtShop = (networks, dropAtShop) =>
  networks?.reduce(
    (acc, curr) =>
      min([
        acc,
        isBoolean(dropAtShop) && dropAtShop
          ? curr.price.fromShop
          : curr.price.fromDoor,
      ]),
    100
  );

/**
 *
 * @param {Array} networks - Array of networks
 * @param {number} shipment - Shipment
 * @returns {Array} - Available networks based on number of parcels and their weights
 */
export const getNetworksByParcelSizeAndWeight = (networks = [], shipment) =>
  networks.filter(({ networkDetails }) => {
    const parcels = get(shipment, 'outboundConsignment.parcels', []);

    return (
      parcels.every(
        ({ _weight }) => _weight <= networkDetails?.maxWeight / 1000
      ) &&
      shipment.outboundConsignment.numberOfParcels <= networkDetails?.maxParcels
    );
  });

/**
 *
 * @param {Array} networks - Array of networks
 * @param {boolean} dropAtShop - Boolean indicator of whether parcel goes to shop
 * @returns {Array} - Sorted array of networks by price based on whether parcel goes to shop
 */
export const sortNetworksByPrice = (networks, dropAtShop) =>
  networks.sort((prev, curr) => {
    if (dropAtShop) {
      return prev?.price?.fromShop - curr?.price?.fromShop;
    } else {
      return prev?.price?.fromDoor - curr?.price?.fromDoor;
    }
  });

const roundTotalWeight = (totalWeight, shipment) => {
  let result = totalWeight;
  const isShipmentDomestic = ShipmentHelpers.isShipmentDomestic(shipment);

  if (isShipmentDomestic) {
    const roundedTotalWeight = Math.ceil(totalWeight * 2) / 2;
    result = roundedTotalWeight !== 0 ? roundedTotalWeight.toFixed(1) : 0.5;
  } else {
    const roundedTotalWeight = Math.round(totalWeight);
    result = roundedTotalWeight !== 0 ? roundedTotalWeight : 1;
  }

  return result;
};

export const getParcelsTotalWeight = (shipment, customParcels = []) => {
  const parcels = customParcels.length
    ? customParcels
    : get(shipment, 'outboundConsignment.parcels');

  const totalWeight = parcels.reduce(
    (acc, curr) =>
      add(
        acc,
        multiply(
          Number(curr._weight || 0),
          Number.parseInt(curr._quantity || 0)
        )
      ),
    0
  );

  return roundTotalWeight(totalWeight, shipment);
};

export const getParcelTotalQuantity = (parcels = []) =>
  parcels.reduce(
    (acc, curr) => add(acc, Number.parseInt(curr._quantity || 0)),
    0
  );

/**
 *
 * @param {Array} networks - List of networks
 * @returns Maximum weight and parcel number for networks
 */
export const getMaxWeightAndParcelNumberFromNetworks = (networks = []) =>
  networks.reduce((acc, curr) => {
    let maxWeight = acc.maxWeight;
    let maxParcelNumber = acc.maxParcelNumber;

    if (curr.networkDetails?.maxWeight) {
      maxWeight = max([maxWeight, curr.networkDetails?.maxWeight / 1000]);
    }
    if (curr.networkDetails?.maxParcels) {
      maxParcelNumber = max([maxParcelNumber, curr.networkDetails?.maxParcels]);
    }

    return { maxWeight, maxParcelNumber };
  }, {});

/**
 *

 * @param {boolean} dropAtShop
 * @param {object} param
 * @param {Array} param.networks - List of networks
 * @param {object} param.collectionPickup - Collection pickup point
 * @param {object} param.deliveryPickup - Delivery pickup point
 * @param {boolean} param. - Boolean indicator of whether parcel goes to shop
 * @returns
 */
export const getMaxWeightAndParcelNumber = (
  dropAtShop,
  { networks, collectionPickup, deliveryPickup }
) => {
  const collectionPickupMaxWeight =
    get(collectionPickup, 'pickupLocation.pickupLocationMaxWeight') / 10;
  const deliveryPickupMaxWeight =
    get(deliveryPickup, 'pickupLocation.pickupLocationMaxWeight') / 10;
  const collectionPickupMaxQuantity = get(
    collectionPickup,
    'pickupLocation.maxParcelQuantity'
  );
  const deliveryPickupMaxQuantity = get(
    deliveryPickup,
    'pickupLocation.maxParcelQuantity'
  );
  const { maxWeight, maxParcelNumber } =
    getMaxWeightAndParcelNumberFromNetworks(networks);

  let minMaxWeight = maxWeight;
  let minMaxParcelNumber = maxParcelNumber;

  if (dropAtShop) {
    minMaxWeight = min([
      collectionPickupMaxWeight || minMaxWeight,
      deliveryPickupMaxWeight || minMaxWeight,
      minMaxWeight,
    ]);
    minMaxParcelNumber = min([
      collectionPickupMaxQuantity || minMaxParcelNumber,
      deliveryPickupMaxQuantity || minMaxParcelNumber,
      minMaxParcelNumber,
    ]);
  }

  return {
    maxWeight: minMaxWeight || 30,
    maxParcelNumber: minMaxParcelNumber || 99,
  };
};

const getTotalPrice = ({
  shipment,
  basePrice,
  baseCollectionPrice,
  baseDeliveryPrice,
}) => {
  let totalPrice = basePrice;

  const isDeliverToDoor =
    get(shipment, FORM.SHIPMENT_FIELDS.DELIVER_TO.KEY) === POINT_TYPE.DOOR;

  const isCollectedFromDoor =
    get(shipment, FORM.SHIPMENT_FIELDS.COLLECT_FROM.KEY) === POINT_TYPE.DOOR;

  if (isDeliverToDoor && !isCollectedFromDoor) {
    totalPrice = baseCollectionPrice;
  }

  if (isDeliverToDoor && isCollectedFromDoor) {
    totalPrice = baseDeliveryPrice;
  }

  return totalPrice;
};

const getExtraWeightPrice = ({ network, shipment, config }) => {
  const isShipmentDomestic = ShipmentHelpers.isShipmentDomestic(shipment);
  const totalWeight = get(shipment, FORM.SHIPMENT_FIELDS.TOTAL_WEIGHT.KEY);
  const baseWeight = isShipmentDomestic
    ? config.minDomParcelWeight
    : get(network, 'price.baseWeight');
  const extraPerKilo = get(network, 'price.extraPerKilo', 0);
  const extraPerHalfKilo = get(network, 'price.extraPerHalfKilo', 0);

  const extraWeight = totalWeight > baseWeight ? totalWeight - baseWeight : 0;

  return extraPerHalfKilo
    ? extraWeight * 2 * extraPerHalfKilo
    : extraWeight * extraPerKilo;
};

const getArrivalServicePrice = ({ network, shipment, config }) => {
  const isCollectedFromDoor =
    get(shipment, FORM.SHIPMENT_FIELDS.COLLECT_FROM.KEY) === POINT_TYPE.DOOR;

  return (
    (isCollectedFromDoor
      ? network?.price?.fromDoor
      : network?.price?.fromShop) +
    getExtraWeightPrice({
      network,
      shipment,
      config,
    })
  );
};

const getArrivalServicesExtraPrices = ({
  networks,
  shipment,
  config,
  minFromDoorPriceNetwork,
}) => {
  const arrivalServicesNetworks = filter(networks, network => {
    const fromDoorPrice = network?.price?.fromDoor;

    return (
      fromDoorPrice && fromDoorPrice >= minFromDoorPriceNetwork?.price?.fromDoor
    );
  });

  const minArrivalServicesPrice = getArrivalServicePrice({
    network: minFromDoorPriceNetwork,
    shipment,
    config,
  });

  return arrivalServicesNetworks.map(network => {
    const servicePrice = getArrivalServicePrice({
      network,
      shipment,
      config,
    });

    return {
      networkKey: network?.networkKey,
      extraPrice: servicePrice - minArrivalServicesPrice,
    };
  });
};

export const getPriceData = ({ price = {}, networks, shipment, config }) => {
  const selectedNetworkCode = get(
    shipment,
    FORM.SHIPMENT_FIELDS.NETWORK_CODE.KEY
  );
  const selectedNetwork = find(networks, { networkKey: selectedNetworkCode });

  const minFromShopPriceNetwork = sortBy(
    networks.filter(network => network.price.fromShop),
    'price.fromShop'
  )[0];

  const basePrice = get(minFromShopPriceNetwork, 'price.fromShop', 0);

  const minFromDoorPriceNetwork = sortBy(
    networks.filter(network => network.price.fromDoor),
    'price.fromDoor'
  )[0];
  const baseCollectionPrice = get(minFromDoorPriceNetwork, 'price.fromShop', 0);
  const baseDeliveryPrice = get(minFromDoorPriceNetwork, 'price.fromDoor', 0);

  const baseExtraWeightPrice = getExtraWeightPrice({
    network: minFromShopPriceNetwork,
    shipment,
    config,
  });

  const selectedNetworkExtraWeightPrice = getExtraWeightPrice({
    network: selectedNetwork || minFromShopPriceNetwork,
    shipment,
    config,
  });

  const totalPrice =
    getTotalPrice({
      shipment,
      basePrice,
      baseCollectionPrice,
      baseDeliveryPrice,
    }) + selectedNetworkExtraWeightPrice;

  const vatRate = get(
    selectedNetwork || minFromShopPriceNetwork,
    'price.vatRate',
    0
  );

  const taxAmount = (totalPrice * vatRate) / (100 + vatRate);

  return {
    ...price,
    basePrise: basePrice + baseExtraWeightPrice,
    deliveryExtraPrice: baseCollectionPrice - basePrice,
    collectionExtraPrice: baseDeliveryPrice - baseCollectionPrice,
    taxAmount: price.taxAmount || taxAmount,
    totalIncVatAmount: price.totalIncVatAmount || totalPrice,
    arrivalServicesExtraPrices: getArrivalServicesExtraPrices({
      networks,
      shipment,
      config,
      minFromDoorPriceNetwork,
    }),
  };
};
