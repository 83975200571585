import { Box, Button, Paper, Typography } from '@mui/material';

import { Add, Remove } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { PARCEL_INSURANCE } from '../../constants/strings';
import configSlice from '../../redux/configSlice';

const getIsProtected = ({
  parcelValue,
  selectedInsuranceValue,
  uncoveredValue,
  mostExpensiveInsurance,
}) =>
  parcelValue <= selectedInsuranceValue ||
  (uncoveredValue >= 0 &&
    mostExpensiveInsurance.insuranceValue === selectedInsuranceValue);

const ParcelInsuranceSection = ({
  parcelValue,
  selectedInsuranceValue,
  onChangeInsurance,
}) => {
  const insuranceConfig = useSelector(configSlice.selectors.getInsuranceConfig);
  const cheapestInsurance = insuranceConfig[0];
  const mostExpensiveInsurance = insuranceConfig[insuranceConfig.length - 1];
  const isCheapestInsuranceEnough =
    parcelValue <= cheapestInsurance.insuranceValue;
  const uncoveredValue = parcelValue - mostExpensiveInsurance.insuranceValue;
  const isProtected = getIsProtected({
    parcelValue,
    selectedInsuranceValue,
    uncoveredValue,
    mostExpensiveInsurance,
  });
  const additionalProtection = isProtected
    ? []
    : insuranceConfig.find(
        insurance => insurance.insuranceValue >= parcelValue
      ) || insuranceConfig[insuranceConfig.length - 1];

  useEffect(() => {
    if (isCheapestInsuranceEnough) {
      onChangeInsurance(cheapestInsurance.insuranceValue);
    }
  }, [isCheapestInsuranceEnough]);

  const handleInsuranceAction = useCallback(() => {
    if (isProtected) {
      onChangeInsurance(cheapestInsurance.insuranceValue);
    } else if (additionalProtection.insuranceCost) {
      onChangeInsurance(additionalProtection.insuranceValue);
    }
  }, [isProtected, additionalProtection]);

  return (
    <>
      <Box>
        <Paper sx={{ p: 2, my: 2, backgroundColor: !isProtected && '#FEEBEE' }}>
          <Box>
            <Typography variant='h3' sx={{ pb: 1 }}>
              {PARCEL_INSURANCE.TITLE}
            </Typography>
            <Typography variant='body1'>
              {PARCEL_INSURANCE.DESCRIPTION(
                additionalProtection.insuranceCost,
                (mostExpensiveInsurance.insuranceValue ===
                  selectedInsuranceValue &&
                  uncoveredValue >= 0 &&
                  mostExpensiveInsurance.insuranceValue) ||
                  null
              )}
            </Typography>
          </Box>
          {!isCheapestInsuranceEnough && (
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                variant={isProtected ? 'contained' : 'outlined'}
                startIcon={isProtected ? <Remove /> : <Add />}
                onClick={handleInsuranceAction}
              >
                {isProtected
                  ? PARCEL_INSURANCE.REMOVE_PROTECTION
                  : PARCEL_INSURANCE.ADD_PROTECTION}
              </Button>
            </Box>
          )}
        </Paper>
      </Box>
    </>
  );
};

export default ParcelInsuranceSection;
