import { createSelector } from '@reduxjs/toolkit';
import { createStructuredSelector } from 'reselect';
import { get } from 'lodash';

import { OrderSliceSelectors } from '../../slice';
import { SHIPMENT_FIELDS } from '../../../../constants/forms';

export const getBillingAddress = createSelector(
  [OrderSliceSelectors.getValues],
  values => {
    const collectionLocality = get(
      values,
      'outboundConsignment.collectionDetails.address.locality'
    );
    return {
      phoneNumber: get(
        values,
        SHIPMENT_FIELDS.COLLECTION_CONTACT_TELEPHONE.KEY
      ),
      streetAddress: get(
        values,
        'outboundConsignment.collectionDetails.address.street'
      ),
      extendedAddress: collectionLocality, // ?
      locality: collectionLocality,
      region: get(
        values,
        'outboundConsignment.collectionDetails.address.county'
      ),
      postalCode: get(
        values,
        'outboundConsignment.collectionDetails.address.postcode'
      ),
      countryCodeAlpha2: get(
        values,
        'outboundConsignment.collectionDetails.address.countryCode'
      ),
    };
  }
);

export const getBillingEmail = createSelector(
  [OrderSliceSelectors.getValues],
  values => get(values, SHIPMENT_FIELDS.COLLECTION_CONTACT_EMAIL.KEY)
);

export const getBillingMobilePhone = createSelector(
  [OrderSliceSelectors.getValues],
  values => get(values, SHIPMENT_FIELDS.COLLECTION_CONTACT_TELEPHONE.KEY)
);

export const getThreeDSecureData = createStructuredSelector({
  email: getBillingEmail,
  mobilePhoneNumber: getBillingMobilePhone,
  billingAddress: getBillingAddress,
});
