import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import Close from '@mui/icons-material/Close';
import ContentCopy from '@mui/icons-material/ContentCopy';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import { useState } from 'react';
import {
  CONFIRMATION,
  COPIED_TO_CLIPBOARD,
  EMAIL_LINK,
  EMAIL_TEMPLATE_BODY_$,
  EMAIL_TEMPLATE_SUBJECT,
  SEND,
} from '../../constants/strings';
import { FMP_BASE_URL } from '../../constants/env';

const ShareModal = ({ open, onClose, parcel = {} }) => {
  const theme = useTheme();
  const { parcelNumber } = parcel;
  const [email, setEmail] = useState('');
  const [copied, setCopied] = useState(false);
  const link = parcelNumber ? `${FMP_BASE_URL}/parcels/${parcelNumber}` : '';
  const hideCopiedMessageTimeout = 2000;

  const handleCopy = () => {
    navigator.clipboard.writeText(link);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, hideCopiedMessageTimeout);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'primary.contrastText',
          position: 'relative',
          m: { sm: '10% auto' },
          borderRadius: 1,
          maxWidth: { sm: 600 },
          width: { sm: '100%', md: '328px' },
          height: {
            xs: '100%',
            sm: 'auto',
          },
          maxHeight: {
            md: '55%',
          },
          minHeight: {
            md: '50%',
          },
          overflowY: {
            xs: 'scroll',
            sm: 'auto',
            md: 'hidden',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            p: (1, 2),
          }}
        >
          <Typography variant='h3' sx={{ py: 1 }}>
            {CONFIRMATION.SHARE_MODAL_TITLE}
          </Typography>
          <IconButton
            aria-label='close'
            sx={{ width: '35px', height: '35px' }}
            onClick={onClose}
          >
            <Close />
          </IconButton>
        </Box>
        <Divider />
        <Box
          sx={{
            p: 2,
            pt: 0,
            display: 'flex',
            justifyContent: 'space-between',
            height: '320px',
            overflow: 'auto',
            flexDirection: 'column',
          }}
        >
          <Box sx={{ my: 1 }}>
            <Typography variant='body2'>{CONFIRMATION.COPY_LINK}</Typography>
            <TextField
              fullWidth
              style={{ background: theme.palette.primary.pageBackground }}
              value={link}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      onClick={handleCopy}
                      edge='end'
                      aria-label='copy link'
                    >
                      <ContentCopy />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{ mt: 2 }}
            />
            {copied && (
              <Typography variant='body2' color='success.main' sx={{ mt: 1 }}>
                {COPIED_TO_CLIPBOARD}
              </Typography>
            )}
          </Box>

          <Divider />
          <Box sx={{ my: 1 }}>
            <Typography variant='body2'>{EMAIL_LINK}</Typography>
            <TextField
              fullWidth
              placeholder='Enter Email address '
              value={email}
              onChange={e => setEmail(e.target.value)}
              sx={{ mt: 2 }}
            />
          </Box>
          <Button
            sx={{ my: 1 }}
            variant='contained'
            component='a'
            href={`mailto:${email}?subject=${encodeURIComponent(
              EMAIL_TEMPLATE_SUBJECT
            )}&body=${encodeURIComponent(EMAIL_TEMPLATE_BODY_$(link))}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            {SEND}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

ShareModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  parcel: PropTypes.shape({
    parcelNumber: PropTypes.string,
  }),
};

export default ShareModal;
