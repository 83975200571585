import Joi from 'joi';
import {
  HEIGHT,
  LENGTH,
  QUANTITY,
  VALUE,
  WEIGHT,
  WIDTH,
} from '../../../constants/forms';
import { PARCEL_DESCRIPTION } from '../../../constants/strings';

const dimensionValidator = Joi.number().greater(0).max(100).messages({
  'number.max': 'Size too long',
});

const maxParcelValue = 99999999.99;
export const minParcelValue = 0.01;

export const parcelSchema = () =>
  Joi.object({
    parcelId: Joi.string(),
    _weight: Joi.number()
      .greater(0)
      .max(Joi.ref('$maxWeight'))
      .label(WEIGHT)
      .messages({
        'number.max': '{#label} must be less than or equal to {$maxWeight}',
      }),
    _quantity: Joi.number()
      .min(1)
      .max(Joi.ref('$maxParcelNumber'))
      .label(QUANTITY)
      .messages({
        'number.max':
          '{#label} must be less than or equal to {$maxParcelNumber}',
      }),
    _width: dimensionValidator.label(WIDTH),
    _height: dimensionValidator.label(HEIGHT),
    _length: dimensionValidator.label(LENGTH),
    _value: Joi.number()
      .required()
      .min(minParcelValue)
      .max(maxParcelValue)
      .messages({
        'number.min': `Min value is ${minParcelValue}`,
        'number.max': `Max value is ${maxParcelValue}`,
      })
      .label(VALUE),
    _description: Joi.string()
      .allow('', null)
      .max(45)
      .label(PARCEL_DESCRIPTION),
    products: Joi.array().items(Joi.object()).allow(null),
  });
