import { useCallback, useEffect } from 'react';
import {
  Box,
  Button,
  FormControl,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { Check, ExpandMore, FilterList, Search } from '@mui/icons-material';
import { Field, Form } from 'react-final-form';
import { get, pick } from 'lodash';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import {
  PICKUP_POINT_AMENITIES_TYPE,
  PICKUP_POINT_TYPE,
} from '../../../constants/pickupPoint';
import useShopFinderControls from '../hooks/useShopFinderControls';
import { FILTER_MENU_ITEMS, MAP_OPTIONS, POSTCODE } from '../constants';
import { FIELDS } from '../../../constants/forms';
import {
  INVALID_POSTCODE,
  PLEASE_ENTER_VALID_$,
} from '../../../constants/strings';
import usePickupLocations from '../../../hooks/usePickupLocations';
import { StringUtil, UrlUtil, Validators } from '../../../utils';

const StyledTypography = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(3, 0, 2, 0),
}));

const StyledBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 3),
  marginTop: theme.spacing(2),
  position: 'relative',
}));

const ButtonsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginTop: theme.spacing(2),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginRight: theme.spacing(1),
  border: `1px solid ${theme.palette.text.primary}`,
  color: 'inherit',
}));

const DropdownMenu = styled(Menu)(({ theme }) => ({
  marginTop: theme.spacing(1),
}));

const CheckIcon = styled(Check)(({ theme }) => ({
  position: 'relative',
  right: theme.spacing(0),
  color: theme.palette.primary.borderLight,
}));

const StyledMenuItem = styled(MenuItem)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

const ShopFinderSearch = ({
  title,
  data,
  setFilteredLocations,
  onSearch,
  hasFilteredLocations,
  responseError,
  kinds = [],
  setIsValidPostcode,
  setActivePickupPoint,
  isValidPostcode = true,
  isModal = false,
  initialPostcodeValue = '',
  formRef,
  currentLocation,
  setSearchAddress,
}) => {
  const urlPostcode = UrlUtil.getURLParam(POSTCODE)?.trim() || '';

  const expandButtonText = () => {
    if (kinds && kinds.length === 1) {
      return getKindLabel(kinds[0]);
    }

    if (selectedAllItem) {
      return selectedAllItem.value;
    }

    return 'All';
  };

  const {
    allAnchorEl,
    allExpanded,
    filterAnchorEl,
    filterExpanded,
    selectedAllItem,
    selectedFilterItem,
    setSelectedFilterItem,
    setSelectedAllItem,
    handleAllClick,
    handleFilterClick,
    handleCloseAll,
    handleCloseFilter,
    handleAllItemClick,
    handleFilterItemClick,
  } = useShopFinderControls(data, setFilteredLocations);

  useEffect(() => {
    applyFilter();
  }, [selectedAllItem, selectedFilterItem, data]);

  const { pickupLocations } = usePickupLocations({
    latitude: currentLocation?.latitude,
    longitude: currentLocation?.longitude,
    ...MAP_OPTIONS,
  });

  useEffect(() => {
    if (urlPostcode === '') {
      if (currentLocation) {
        setFilteredLocations(pickupLocations);
      } else {
        setFilteredLocations([]);
      }
    }
  }, [urlPostcode, setFilteredLocations]);

  const handleInputChange = event => {
    if (!event.target.value && !isModal) {
      UrlUtil.removeURLParam(POSTCODE);
      setSearchAddress(null);
      if (currentLocation) {
        setFilteredLocations(pickupLocations);
      } else {
        setFilteredLocations([]);
      }
    }
    setIsValidPostcode(true);
    setSelectedAllItem(null);
  };

  const performSearch = formValues => {
    const postcode = formValues.postcode.trim();

    if (Validators.isUKPostcodeValid(postcode)) {
      onSearch(postcode);
      setActivePickupPoint(null);
      setSelectedFilterItem([]);
      applyFilter();
      if (!isModal) {
        UrlUtil.updateURLWithParams(POSTCODE, postcode);
      }
    } else {
      setIsValidPostcode(false);
    }
  };

  const handleFormSubmit = useCallback(
    formValues => {
      try {
        performSearch(formValues);
      } catch (error) {
        console.warn(error);
      }
    },
    [performSearch]
  );

  const getPickupFilteredJSON = (pickupPoint, criteria) =>
    pick(get(pickupPoint, 'pickupLocation'), [
      PICKUP_POINT_AMENITIES_TYPE.WHEELCHAIR_ACCESS,
      PICKUP_POINT_AMENITIES_TYPE.CAR_PARKING,
      PICKUP_POINT_AMENITIES_TYPE.OPEN_LATE,
      PICKUP_POINT_AMENITIES_TYPE.OPEN_SATURDAYS,
      PICKUP_POINT_AMENITIES_TYPE.OPEN_SUNDAYS,
      PICKUP_POINT_AMENITIES_TYPE.LABEL_PRINTING,
    ])[criteria];

  const getPickupFilteredByType = (pickupPoint, criteria) =>
    get(pickupPoint, 'pickupLocation.kind') === criteria;

  const filterPickupPoints = (
    pickupPoints,
    filterCriteria = [],
    typeCriteria
  ) =>
    pickupPoints.filter(pickupPoint => {
      const matchesFilter = filterCriteria.length
        ? filterCriteria.every(criteria =>
            getPickupFilteredJSON(pickupPoint, criteria)
          )
        : true;
      const matchesType = typeCriteria
        ? getPickupFilteredByType(pickupPoint, typeCriteria)
        : true;
      return matchesFilter && matchesType;
    });

  const applyFilter = useCallback(() => {
    const filteredData = filterPickupPoints(
      data,
      selectedFilterItem || [],
      selectedAllItem?.key
    );
    setFilteredLocations(filteredData);
  }, [data, selectedFilterItem, selectedAllItem, setFilteredLocations]);

  const getKindLabel = kind =>
    Object.keys(PICKUP_POINT_TYPE).find(
      key => PICKUP_POINT_TYPE[key] === kind
    ) || kind;

  return (
    <StyledBox id='shop-finder-search'>
      {title && <StyledTypography variant='h3'>{title}</StyledTypography>}
      <Box sx={{ mt: 1 }}>
        <Form
          onSubmit={handleFormSubmit}
          ref={formRef}
          initialValues={{ postcode: initialPostcodeValue || urlPostcode }}
          render={({ handleSubmit }) => (
            <Box
              component='form'
              autoComplete='off'
              onSubmit={handleSubmit}
              sx={{ display: 'flex', flexDirection: 'column' }}
            >
              <FormControl fullWidth>
                <Field name={FIELDS.POSTCODE.KEY}>
                  {({ input, meta }) => (
                    <>
                      <TextField
                        id='postcode'
                        label={FIELDS.POSTCODE.LABEL}
                        placeholder={FIELDS.POSTCODE.PLACEHOLDER}
                        variant='standard'
                        error={!!(meta.touched && meta.error)}
                        {...input}
                        required
                        onChange={e => {
                          input.onChange(e);
                          handleInputChange(e);
                        }}
                        onKeyDown={event => {
                          if (event.key === 'Enter') {
                            event.preventDefault();
                            event.target.blur();
                            handleSubmit();
                          }
                        }}
                      />
                      <Button
                        sx={{
                          alignSelf: 'flex-end',
                          top: '12px',
                          position: 'absolute',
                          backgroundColor: 'transparent',
                          '&:hover': {
                            backgroundColor: 'transparent',
                          },
                          '&:focus': {
                            backgroundColor: 'transparent',
                          },
                        }}
                        type='submit'
                      >
                        <Search />
                      </Button>
                    </>
                  )}
                </Field>
                {!isValidPostcode && (
                  <StyledTypography color='error' variant='caption'>
                    {`${INVALID_POSTCODE}. ${StringUtil.formatMessage(
                      PLEASE_ENTER_VALID_$,
                      'UK postcode'
                    )}.`}
                  </StyledTypography>
                )}
              </FormControl>
            </Box>
          )}
        />
      </Box>
      {(selectedFilterItem.length !== 0 || hasFilteredLocations) &&
        !responseError &&
        isValidPostcode && (
          <>
            <ButtonsContainer>
              <StyledButton
                onClick={handleAllClick}
                variant='outlined'
                color='inherit'
                disabled={kinds && kinds.length <= 1}
              >
                {expandButtonText()}{' '}
                <ExpandMore
                  style={{
                    transition: 'transform 0.3s ease',
                    transform: allExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
                  }}
                />
              </StyledButton>
              <StyledButton
                onClick={handleFilterClick}
                variant='outlined'
                color='inherit'
              >
                Filters <FilterList />
              </StyledButton>
            </ButtonsContainer>
            {kinds && kinds.length > 1 && (
              <DropdownMenu
                anchorEl={allAnchorEl}
                open={allExpanded}
                onClose={handleCloseAll}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                getContentAnchorEl={null}
              >
                <StyledMenuItem
                  key='all'
                  onClick={() =>
                    handleAllItemClick({ key: null, value: 'All' })
                  }
                >
                  <Typography variant='body2'>ALL</Typography>
                  {!selectedAllItem && <CheckIcon />}
                </StyledMenuItem>
                {kinds.map(kind => (
                  <StyledMenuItem
                    key={kind}
                    onClick={() =>
                      handleAllItemClick({
                        key: kind,
                        value: getKindLabel(kind),
                      })
                    }
                  >
                    <Typography variant='body2'>
                      {getKindLabel(kind)}
                    </Typography>
                    {selectedAllItem?.key === kind && <CheckIcon />}
                  </StyledMenuItem>
                ))}
              </DropdownMenu>
            )}
            <DropdownMenu
              anchorEl={filterAnchorEl}
              open={filterExpanded}
              onClose={handleCloseFilter}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              getContentAnchorEl={null}
            >
              {FILTER_MENU_ITEMS.map(item => (
                <MenuItem
                  key={item.key}
                  onClick={() => handleFilterItemClick(item)}
                >
                  {item.value}{' '}
                  {selectedFilterItem &&
                    selectedFilterItem.includes(item.key) && <CheckIcon />}
                </MenuItem>
              ))}
            </DropdownMenu>
          </>
        )}
    </StyledBox>
  );
};

ShopFinderSearch.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array.isRequired,
  setActivePickupPoint: PropTypes.func,
  setFilteredLocations: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  hasFilteredLocations: PropTypes.bool.isRequired,
  responseError: PropTypes.bool,
  kinds: PropTypes.array,
  setIsValidPostcode: PropTypes.func.isRequired,
  isValidPostcode: PropTypes.bool,
  setSearchAddress: PropTypes.func,
};

export default ShopFinderSearch;
