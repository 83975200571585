/* eslint-disable react/display-name */

import {
  forwardRef,
  useMemo,
  useRef,
  useCallback,
  useState,
  useEffect,
} from 'react';
import Map, { Marker } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { Box, Button, styled } from '@mui/material';
import { indexOf, find } from 'lodash';

import { useTracker } from '@dpdgroupuk/react-event-tracker';
import PlaceIcon from '@mui/icons-material/Place';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useTheme } from '@emotion/react';
import { useLocation } from 'react-router-dom';
import {
  calculateMapFitBounds,
  getPickupLocationCode,
} from '../../utils/pickupPoint';
import { ADD_ADDRESS_PICKUP_POINT } from '../../constants/analytics';
import { PICKUP_POINT_LIST_TITLE } from '../../constants/strings';
import usePickupLocations from '../../hooks/usePickupLocations';
import {
  LocationProvider,
  useLocationController,
} from '../../features/Location';
import useBreakpoint from '../../hooks/useBreakpoint';
import PickupShop from './components/PickupShop';
import { ITEM_HEIGHT, POSTCODE, MAP_OPTIONS } from './constants';
import PickupPointsList from './components/PickupPointsList';
import ShopFinderSearch from './components/ShopFinderSearch';

const StyledContainer = styled(Box)(({ theme, hasFilteredLocations }) => ({
  borderRadius: '0 10px 10px 10px',
  border: '1px solid #999999',
  overflow: 'hidden',
  '& .mapboxgl-canvas': {
    borderRadius: '0',
  },
  [theme.breakpoints.down('md')]: {
    height: hasFilteredLocations ? 'calc(40vh - 100px)' : 'calc(80vh - 100px)',
    width: '100%',
  },
}));

const StyledShowHideButton = styled(Button)(({ theme, isListVisible }) => ({
  display: 'block',
  position: 'absolute',
  top: '20px',
  right: '20px',
  background: 'none',
  color: theme.palette.primary.contrastText,
  transition: '0.35s',
  transform: isListVisible ? 'rotate(180deg)' : 'rotate(0deg)',
  zIndex: 10,
  '&:hover': {
    background: 'none',
  },
}));

const getMobileHeight = (isListVisible, activePickupPoint) => {
  if (!isListVisible) {
    return '23vh';
  }
  if (activePickupPoint) {
    return '100%';
  }
  return '64%';
};

const getHeight = (isModal, isListVisible) => {
  if (isModal) {
    return isListVisible ? '55vh' : '25vh';
  }
  return 'calc(100vh - 64px)';
};

const StyledBox = styled(Box)(
  ({ theme, isModal, isListVisible, activePickupPoint }) => {
    const mobileHeight = getMobileHeight(isListVisible, activePickupPoint);
    const height = getHeight(isModal, isListVisible);

    return {
      width: isModal ? '100%' : '28%',
      minWidth: '300px',
      maxWidth: '500px',
      position: isModal ? 'absolute' : 'static',
      bottom: isModal ? '0' : 'auto',
      transition: '0.35s',
      maxHeight: !isListVisible && isModal ? '190px' : '',
      height,
      zIndex: isModal ? '3' : 'auto',
      overflow: 'scroll',
      background: isModal ? theme.palette.secondary.main : 'none',
      boxShadow: theme.palette.primary.shadow,
      [theme.breakpoints.down('md')]: {
        zIndex: '3',
        width: '100%',
        background: theme.palette.secondary.main,
        position: 'absolute',
        bottom: 0,
        height: mobileHeight,
        transition: '0.35s',
        boxShadow: 'none',
        marginBottom: 0,
        paddingBottom: 0,
        maxWidth: 'none',
      },
    };
  }
);

const StyledPlaceIcon = styled(PlaceIcon)(({ theme }) => ({
  color: theme.palette.primary.directionBlue,
  fontSize: '35px',
}));

const MapBox = forwardRef(
  ({
    style,
    isModal = false,
    onChange,
    initialPostcodeValue,
    showSelectButton,
    ...props
  }) => {
    const [searchAddress, setSearchAddress] = useState(null);
    const [hasFilteredLocations, setHasFilteredLocations] = useState(false);
    const [filteredLocations, setFilteredLocations] = useState([]);
    const isSmallScreen = useBreakpoint('md');

    const [currentLocation, setCurrentLocation] = useState();
    const [isValidPostcode, setIsValidPostcode] = useState(true);
    const location = useLocation();

    const mapPadding =
      window.innerWidth > 768
        ? { top: 106, bottom: 80, left: 60, right: 60 }
        : { top: 86, bottom: 60, left: 40, right: 40 };

    const searchParams = new URLSearchParams(location.search);
    const postcode = searchParams.get(POSTCODE);

    useEffect(() => {
      if (postcode !== null) {
        setSearchAddress(postcode);
      }
      if (initialPostcodeValue && !postcode) {
        setSearchAddress(initialPostcodeValue);
      }
    }, [location.search, initialPostcodeValue, postcode]);

    const { pickupLocations, loading, error, kinds, setPickupLocations } =
      usePickupLocations({
        latitude: currentLocation?.latitude,
        longitude: currentLocation?.longitude,
        searchAddress,
        ...MAP_OPTIONS,
      });

    useEffect(() => {
      setHasFilteredLocations(filteredLocations.length > 0);
      if (isModal && filteredLocations.length > 0) {
        setIsListVisible(true);
      }
    }, [filteredLocations]);

    const styleMap = {
      width: '100%',
      height: '100%',
      position: 'relative',
      background: 'rgb(26, 29, 33)',
      borderRadius: 0,
      border: 'none',
      zIndex: '1',
      ...style,
    };

    const [activePickupPoint, setActivePickupPoint] = useState();
    const [isListVisible, setIsListVisible] = useState(false);
    const listRef = useRef(null);
    const activePickupLocationCode = getPickupLocationCode(activePickupPoint);
    const {
      permissionStatus,
      currentPosition,
      error: positionError,
    } = useLocationController();

    useEffect(() => {
      const fetchLocation = () => {
        if (positionError) {
          return;
        }
        if (currentPosition) {
          const { latitude, longitude } = currentPosition;
          setCurrentLocation({ latitude, longitude });
        }
      };

      if (permissionStatus) {
        if (permissionStatus.granted) {
          fetchLocation();
        } else if (permissionStatus.denied) {
          if (postcode) {
            setSearchAddress(postcode);
          } else if (!postcode && initialPostcodeValue) {
            setSearchAddress(initialPostcodeValue);
          }
          setPickupLocations([]);
          setCurrentLocation(null);
        } else {
          setCurrentLocation(null);
        }
      }
    }, [currentPosition, postcode, initialPostcodeValue]);

    const fitActivePickup =
      activePickupPoint &&
      find(pickupLocations, [
        'pickupLocation.pickupLocationCode',
        activePickupLocationCode,
      ]);

    const pickupPointsToFit = fitActivePickup
      ? [fitActivePickup]
      : pickupLocations;

    const fitBounds = useMemo(
      () =>
        calculateMapFitBounds({
          currentLocation,
          pickupLocations: pickupPointsToFit,
        }),
      [currentLocation, pickupPointsToFit]
    );

    const MapRef = useRef();
    const formRef = useRef(null);
    const tracker = useTracker();
    const theme = useTheme();

    //Map view port positions
    const pickupMapPadding = {
      top: !isModal ? 125 : 0,
      bottom: !isModal ? 175 : 375,
      left: !activePickupLocationCode ? 160 : 405,
      right: !isModal ? 60 : 140,
    };

    useMemo(() => {
      if (MapRef.current) {
        MapRef.current.fitBounds(fitBounds, {
          padding: pickupMapPadding,
          duration: 3000,
        });
      }
    }, [fitBounds, pickupMapPadding]);

    const initialStateProp = useMemo(
      () => ({
        bounds: fitBounds,
        fitBoundsOptions: {
          padding: pickupMapPadding,
        },
      }),
      []
    );

    const initialState = {
      longitude: currentLocation?.longitude,
      latitude: currentLocation?.latitude,
      fitBoundsOptions: {
        padding: mapPadding,
      },
      ...initialStateProp,
    };

    const handleKeyboardDismiss = () => {
      if (listRef.current) {
        listRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
        });
      }
    };

    const handleSearch = useCallback(address => {
      if (!isModal) {
        scrollToTop();
      }
      if (address) {
        setSearchAddress(address);
        handleKeyboardDismiss();
        setIsListVisible(true);
      } else {
        setSearchAddress(null);
      }
    }, []);

    const pickupListRef = useRef();

    const onMapPinClick = useCallback(() => {
      tracker.logEvent(ADD_ADDRESS_PICKUP_POINT.ON_MAP_PIN_CLICK);
    }, [tracker]);

    const onSelectClick = useCallback(
      pickupPoint => {
        setActivePickupPoint(pickupPoint);
        setIsListVisible(true);
        pickupListRef?.current?.scroll({
          top: 0,
        });
      },
      [setActivePickupPoint]
    );

    const handleMapPinClick = useCallback(
      pickupPoint => {
        onSelectClick(pickupPoint);

        const isPickupDetailsActive =
          getPickupLocationCode(activePickupPoint) ===
          getPickupLocationCode(pickupPoint);

        if (isPickupDetailsActive) {
          setActivePickupPoint(null);
        } else {
          const indexOfPickup = indexOf(filteredLocations, pickupPoint);
          pickupListRef?.current?.scroll({
            top: indexOfPickup * ITEM_HEIGHT,
            behavior: 'smooth',
          });

          setActivePickupPoint(pickupPoint);
        }
        onMapPinClick();
      },
      [filteredLocations, activePickupPoint, pickupListRef, onMapPinClick]
    );

    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
      if (hasFilteredLocations && isSmallScreen) {
        scrollToTop();
      }
    }, [hasFilteredLocations, isSmallScreen]);

    useEffect(() => {
      if (activePickupPoint && isSmallScreen) {
        document.body.classList.add('overflow-hidden');
      } else {
        document.body.classList.remove('overflow-hidden');
      }
      return () => {
        document.body.classList.remove('overflow-hidden');
      };
    }, [activePickupPoint, isSmallScreen]);

    const MapComponent = useMemo(
      () => (
        <Map
          mapboxAccessToken={import.meta.env.VITE_MAPBOX_API_TOKEN}
          initialViewState={initialState}
          style={styleMap}
          ref={MapRef}
          fitBounds={fitBounds}
          mapStyle='mapbox://styles/mapbox/streets-v11'
          logoPosition='top-right'
          {...props}
        >
          {currentLocation && (
            <Marker
              longitude={currentLocation.longitude}
              latitude={currentLocation.latitude}
              anchor='bottom'
            >
              <StyledPlaceIcon />
            </Marker>
          )}
          {filteredLocations.map((pickupPoint, index) => {
            const pickupLocationCode = getPickupLocationCode(pickupPoint);

            return (
              <PickupShop
                key={pickupLocationCode}
                pickupShop={pickupPoint}
                number={pickupPoint.number || index + 1}
                onPickupClick={handleMapPinClick}
                interactive={true}
                isActive={pickupLocationCode === activePickupLocationCode}
              />
            );
          })}
        </Map>
      ),
      [fitBounds, currentLocation, filteredLocations, activePickupLocationCode]
    );

    return isSmallScreen ? (
      <>
        <StyledBox
          id='map-box-mobile'
          sx={{ overflow: activePickupPoint ? 'hidden' : 'scroll' }}
          isListVisible={isListVisible}
          isModal={isModal}
          hasFilteredLocations={hasFilteredLocations}
          ref={listRef}
          activePickupPoint={activePickupPoint}
        >
          {hasFilteredLocations && (
            <StyledShowHideButton
              isListVisible={isListVisible}
              onClick={() => setIsListVisible(prev => !prev)}
            >
              <ExpandLessIcon color='primary' />
            </StyledShowHideButton>
          )}
          <ShopFinderSearch
            data={pickupLocations}
            title={PICKUP_POINT_LIST_TITLE}
            setFilteredLocations={setFilteredLocations}
            searchAddress={searchAddress}
            setSearchAddress={setSearchAddress}
            onSearch={handleSearch}
            setActivePickupPoint={setActivePickupPoint}
            hasFilteredLocations={hasFilteredLocations}
            responseError={error}
            kinds={kinds}
            setIsValidPostcode={setIsValidPostcode}
            isValidPostcode={isValidPostcode}
            isModal={isModal}
            initialPostcodeValue={initialPostcodeValue}
            currentLocation={currentLocation}
          />
          {isValidPostcode && isListVisible && (
            <PickupPointsList
              pickupMapPadding={pickupMapPadding}
              onSelectClick={onSelectClick}
              MapRef={MapRef}
              currentLocation={currentLocation}
              activePickupPoint={activePickupPoint}
              setActivePickupPoint={setActivePickupPoint}
              pickupPoints={filteredLocations}
              pickupListRef={pickupListRef}
              responseError={error}
              loading={loading}
              isValidPostcode={isValidPostcode}
              isModal={isModal}
              onChange={onChange}
              hasFilteredLocations={hasFilteredLocations}
              showSelectButton={showSelectButton}
            />
          )}
        </StyledBox>
        <StyledContainer
          style={{
            width: '73%',
            height: '69vh',
            display: 'flex',
            flexGrow: 1,
            borderRadius: 0,
            border: 'none',
            flexDirection: 'column-revers',
            marginTop: '56px',
          }}
          hasFilteredLocations={hasFilteredLocations}
        >
          <LocationProvider>{MapComponent}</LocationProvider>
        </StyledContainer>
      </>
    ) : (
      <Box
        id='map-box'
        sx={{
          display: 'flex',
          width: '100%',
          flexDirection: isModal ? 'column-reverse' : 'row',
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column-reverse',
          },
        }}
      >
        <StyledBox
          isModal={isModal}
          isListVisible={isListVisible}
          hasFilteredLocations={hasFilteredLocations}
        >
          {isModal && (
            <StyledShowHideButton
              isListVisible={isListVisible}
              onClick={() => setIsListVisible(prev => !prev)}
            >
              <ExpandLessIcon color='primary' />
            </StyledShowHideButton>
          )}
          <ShopFinderSearch
            data={pickupLocations}
            title={PICKUP_POINT_LIST_TITLE}
            setActivePickupPoint={setActivePickupPoint}
            setFilteredLocations={setFilteredLocations}
            searchAddress={searchAddress}
            setSearchAddress={setSearchAddress}
            onSearch={handleSearch}
            hasFilteredLocations={hasFilteredLocations}
            responseError={error}
            kinds={kinds}
            setIsValidPostcode={setIsValidPostcode}
            isValidPostcode={isValidPostcode}
            isModal={isModal}
            initialPostcodeValue={initialPostcodeValue}
            currentLocation={currentLocation}
            formRef={formRef}
          />
          {(isModal && isListVisible) || !isModal
            ? isValidPostcode && (
                <PickupPointsList
                  pickupMapPadding={pickupMapPadding}
                  onSelectClick={onSelectClick}
                  MapRef={MapRef}
                  currentLocation={currentLocation}
                  activePickupPoint={activePickupPoint}
                  setActivePickupPoint={setActivePickupPoint}
                  pickupPoints={filteredLocations}
                  pickupListRef={pickupListRef}
                  responseError={error}
                  loading={loading}
                  isValidPostcode={isValidPostcode}
                  isModal={isModal}
                  onChange={onChange}
                  hasFilteredLocations={hasFilteredLocations}
                  showSelectButton={showSelectButton}
                />
              )
            : null}
        </StyledBox>
        <StyledContainer
          sx={{
            width: isModal ? '100%' : '73%',
            height: `calc(100vh - 64px)`,
            display: 'flex',
            flexGrow: 1,
            borderRadius: 0,
            border: 'none',
          }}
          hasFilteredLocations={hasFilteredLocations}
        >
          <LocationProvider>{MapComponent}</LocationProvider>
        </StyledContainer>
      </Box>
    );
  }
);

export default MapBox;
