import { createSelector } from '@reduxjs/toolkit';
import { get, set } from 'lodash';
import Joi from 'joi';
import { OrderSliceSelectors } from '../../slice';
import { ObjectUtil } from '../../../../utils';
import {
  getMaxWeightAndParcelNumber,
  getNetworksByParcelSizeAndWeight,
  sortNetworksByPrice,
} from '../../../../utils/networks';
import { SHIPMENT_FIELDS } from '../../../../constants/forms';
import { POINT_TYPE } from '../../../../constants';
import { parcelSchema } from '../../validators/parcel';
import { requireKeysSchema } from '../../../../utils/validators';
import { REQUIRED_TYPE } from '../../../../constants/shipment';

export const getOutboundNetworkParameters = createSelector(
  [OrderSliceSelectors.getValues],
  values => {
    const data = ObjectUtil.pickDeep(values, [
      'outboundConsignment.deliveryDetails.address.countryCode',
      'outboundConsignment.deliveryDetails.address.town',
      'outboundConsignment.deliveryDetails.address.county',
      'outboundConsignment.deliveryDetails.address.postcode',

      'outboundConsignment.collectionDetails.address.countryCode',
      'outboundConsignment.collectionDetails.address.town',
      'outboundConsignment.collectionDetails.address.county',
      'outboundConsignment.collectionDetails.address.postcode',

      SHIPMENT_FIELDS.TOTAL_WEIGHT.KEY,
      SHIPMENT_FIELDS.NUMBER_OF_PARCELS.KEY,
    ]);

    if (!get(data, SHIPMENT_FIELDS.TOTAL_WEIGHT.KEY)) {
      set(data, SHIPMENT_FIELDS.TOTAL_WEIGHT.KEY, 1);
    }

    if (!get(data, SHIPMENT_FIELDS.NUMBER_OF_PARCELS.KEY)) {
      set(data, SHIPMENT_FIELDS.NUMBER_OF_PARCELS.KEY, 1);
    }

    return data.outboundConsignment;
  }
);

export const getNetworksByParcelParams = createSelector(
  [OrderSliceSelectors.getReferences, OrderSliceSelectors.getValues],
  ({ networks }, values) => getNetworksByParcelSizeAndWeight(networks, values)
);

export const getFilteredNetworks = createSelector(
  [getNetworksByParcelParams, OrderSliceSelectors.getValues],
  (networks, values) => {
    let filteredNetworks = networks;
    const collectFrom = get(values, SHIPMENT_FIELDS.COLLECT_FROM.KEY);
    const deliveryTo = get(values, SHIPMENT_FIELDS.DELIVER_TO.KEY);

    if (deliveryTo === POINT_TYPE.SHOP) {
      filteredNetworks = networks.filter(
        ({ networkDetails }) => networkDetails?.shipToPickup === 'Y'
      );
    } else {
      filteredNetworks = networks.filter(({ price, networkDetails }) => {
        if (collectFrom === POINT_TYPE.SHOP) {
          return price.fromShop && networkDetails?.shipToPickup !== 'Y';
        } else {
          return price.fromDoor && networkDetails?.shipToPickup !== 'Y';
        }
      });
    }

    return sortNetworksByPrice(
      filteredNetworks,
      collectFrom === POINT_TYPE.SHOP
    );
  }
);

export const getSelectedNetwork = createSelector(
  [OrderSliceSelectors.getReferences, OrderSliceSelectors.getValues],
  (references, shipment) =>
    references.networks.find(
      n => n.networkKey === get(shipment, SHIPMENT_FIELDS.NETWORK_CODE.KEY)
    )
);

export const getMaxWeightAndNumberOfParcels = createSelector(
  [OrderSliceSelectors.getReferences, OrderSliceSelectors.getValues],
  (references, values) => {
    const dropAtShop = get(values, SHIPMENT_FIELDS.DROP_AT_SHOP.KEY);

    return getMaxWeightAndParcelNumber(dropAtShop, references);
  }
);

export const validateParcelsValues = createSelector(
  [OrderSliceSelectors.getValues, getMaxWeightAndNumberOfParcels],
  (values, context) => {
    const { error } = Joi.array()
      .items(
        requireKeysSchema(parcelSchema(), [
          '_weight',
          '_quantity',
          '_value',
          '_width',
          '_height',
          '_length',
        ])
      )
      .min(1)
      .validate(values?.outboundConsignment?.parcels, {
        context,
      });
    return error;
  }
);

export const getParcelDetailsRequiredFields = createSelector(
  [OrderSliceSelectors.getReferences, OrderSliceSelectors.getValues],
  (_, values) =>
    // do all business logic here to make fields required
    {
      const baseRequiredFields = [
        SHIPMENT_FIELDS.DELIVER_TO.KEY,
        SHIPMENT_FIELDS.DESTINATION_COUNTRY.KEY,
        SHIPMENT_FIELDS.DESTINATION_POSTCODE.KEY,
        SHIPMENT_FIELDS.DESTINATION_STREET.KEY,
        SHIPMENT_FIELDS.DESTINATION_CITY.KEY,

        SHIPMENT_FIELDS.COLLECT_FROM.KEY,
        SHIPMENT_FIELDS.COLLECTION_COUNTRY.KEY,
        SHIPMENT_FIELDS.COLLECTION_POSTCODE.KEY,
        SHIPMENT_FIELDS.COLLECTION_STREET.KEY,
        SHIPMENT_FIELDS.COLLECTION_CITY.KEY,

        SHIPMENT_FIELDS.NETWORK_CODE.KEY,
        SHIPMENT_FIELDS.TOTAL_WEIGHT.KEY,
        SHIPMENT_FIELDS.NUMBER_OF_PARCELS.KEY,
      ];

      if (get(values, SHIPMENT_FIELDS.COLLECT_FROM.KEY) === POINT_TYPE.DOOR) {
        baseRequiredFields.push(SHIPMENT_FIELDS.SHIPMENT_DATE.KEY);
      }

      return baseRequiredFields;
    }
);

export const getParcelDetailsRequiredFieldsMap = createSelector(
  [getParcelDetailsRequiredFields],
  requiredFields =>
    requiredFields.reduce((acc, item) => {
      acc[item] = true;
      return acc;
    }, {})
);

export const getShipToShopAvailable = createSelector(
  [getNetworksByParcelParams],
  networks =>
    networks.some(
      ({ networkDetails }) =>
        networkDetails.shipToPickup === REQUIRED_TYPE.OPTIONAL
    )
);
