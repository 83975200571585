import { isEmpty, isNil, uniq } from 'lodash';

import { STRINGS } from '../constants';
import { joinStringsWith, joinStringsWithComma } from './formatters';

export const formatAddressWithPostcode = address =>
  joinStringsWithComma(
    uniq([
      address?.organisation,
      joinStringsWith(uniq([address?.property, address?.street]), ' '),
      address?.town,
      address?.county,
      address?.postcode,
    ])
  );

export const transformAddressValues = ({ street, locality, property }) => {
  let addressLine1;
  let addressLine2;

  if (property) {
    addressLine1 = `${property} ${street}`;
  } else {
    addressLine1 = street;
  }

  if (addressLine1 && addressLine1.length > 35) {
    addressLine1 = property;
    addressLine2 = `${street}, ${locality}`;

    if (addressLine2 && addressLine2.length > 35) {
      addressLine2 = street;
    }
  } else {
    addressLine2 = locality;
  }

  return {
    addressLine1,
    addressLine2,
  };
};

export const formatCoordinates = (address = {}) => {
  const latitude = address.Latitude ?? address.latitude;
  const longitude = address.Longitude ?? address.longitude;

  if (
    isNil(latitude) ||
    isEmpty(latitude) ||
    isNil(longitude) ||
    isEmpty(longitude)
  ) {
    return null;
  }
  return { latitude: parseFloat(latitude), longitude: parseFloat(longitude) };
};

export const isCountryGB = countryCode => countryCode === STRINGS.GB;

export const isCountryDomestic = countryCode =>
  countryCode === STRINGS.GB || countryCode === STRINGS.IE;

export const isRegionBT = postcode => postcode.toUpperCase().startsWith('BT');

export const isNiShipment = (countryCode, postcode) =>
  isCountryGB(countryCode) && isRegionBT(postcode);

export const getCountryValue = ({ countries = [], countryCode }) =>
  countries.find(({ countryKey }) => countryKey === countryCode);
