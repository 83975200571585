import { Typography, IconButton, Box } from '@mui/material';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { ShipmentPrice } from '@dpdgroupuk/consumer-shipping-helper';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import {
  DefaultBasketCard,
  BasketCardHeader,
} from '../../../components/BasketCard';

import ConfigSlice from '../../../redux/configSlice';
import useBreakpoint from '../../../hooks/useBreakpoint';

import BasketCardFooter from './BasketCardFooter';
import IncompleteOrderAlert from './IncompleteOrderAlert';

const BasketCard = ({
  basketItem,
  references,
  onRemove,
  isInCheckoutList,
  onAddToCheckout,
  onRemoveFromCheckout,
}) => {
  const isSmallScreen = useBreakpoint('sm');
  const [isContentOpened, setIsContentOpened] = useState(!isSmallScreen);
  const { shipment } = basketItem;
  const priceConfig = useSelector(ConfigSlice.selectors.getPriceConfig);

  const price = ShipmentPrice.calculateShipmentPrice(
    shipment,
    references.networkPrice,
    priceConfig
  ).toViewJSON(2);

  const cardPrice = price.totalIncVatAmount && (
    <Box
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
    >
      <Typography variant='h3' color='primary.main'>
        {`£${price.totalIncVatAmount}`}
      </Typography>
      {isSmallScreen && (
        <IconButton
          onClick={() => setIsContentOpened(!isContentOpened)}
          sx={{ paddingRight: 0 }}
        >
          {isContentOpened ? <ArrowDropDown /> : <ArrowDropUp />}
        </IconButton>
      )}
    </Box>
  );

  return (
    <DefaultBasketCard
      outboundConsignment={shipment?.outboundConsignment}
      containerStyles={{ mt: 2 }}
      contentContainerStyles={{ p: 2, py: 3 }}
      isContentOpened={isContentOpened || !isSmallScreen}
      references={references}
      header={
        <>
          <BasketCardHeader shipment={shipment} rightCardContent={cardPrice} />
          {!shipment._valid && <IncompleteOrderAlert />}
        </>
      }
      footer={
        <BasketCardFooter
          basketItem={basketItem}
          onAddToCheckout={onAddToCheckout}
          onRemoveFromCheckout={onRemoveFromCheckout}
          onRemove={onRemove}
          isInCheckoutList={isInCheckoutList}
        />
      }
    />
  );
};

BasketCard.propTypes = {
  references: PropTypes.shape({
    collectionPickup: PropTypes.object,
    deliveryPickup: PropTypes.object,
    networkPrice: PropTypes.object,
  }),
  basketItem: PropTypes.shape({
    basketItemId: PropTypes.string,
    shipment: PropTypes.object,
  }),
  onRemove: PropTypes.func,
  isInCheckoutList: PropTypes.bool,
  onAddToCheckout: PropTypes.func,
  onRemoveFromCheckout: PropTypes.func,
};

export default BasketCard;
