import { useState } from 'react';
import Box from '@mui/material/Box';
import { Form, Field } from 'react-final-form';
import Button from '@mui/material/Button';
import { FormControl, InputLabel, Select } from '@mui/material';
import { SEND_NOW } from '../../constants/strings';
import { required } from '../../utils/validators';
import { FIELDS } from '../../constants/forms';
import AddressLookup from '../AddressLookup/AddressLookup';
import CountriesSelect from '../CountriesSelect';

const QuoteForm = ({ countries = [], onSubmit }) => {
  const [countryISO, setCountryISO] = useState('');
  return (
    <Box sx={{ width: '229px' }}>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting, invalid }) => (
          <Box
            component='form'
            noValidate
            autoComplete='off'
            onSubmit={handleSubmit}
            sx={{ display: 'flex', flexDirection: 'column' }}
          >
            <Field name={FIELDS.DESTINATION_COUNTRY.KEY} validate={required}>
              {props => (
                <FormControl variant='filled'>
                  <InputLabel id={`${FIELDS.DESTINATION_COUNTRY.KEY}_label`}>
                    {FIELDS.DESTINATION_COUNTRY.LABEL}
                  </InputLabel>
                  <CountriesSelect
                    component={Select}
                    countries={countries}
                    labelId={`${FIELDS.DESTINATION_COUNTRY.KEY}_label`}
                    size='small'
                    blurOnSelect
                    select
                    autoWidth
                    disabled={!countries.length}
                    MenuProps={{ PaperProps: { sx: { maxHeight: 500 } } }}
                    onClose={props.input.onBlur}
                    {...props.input}
                    onChange={event => {
                      setCountryISO(event.target.value);
                      props.input.onChange(event);
                    }}
                  />
                </FormControl>
              )}
            </Field>
            <Field
              name={FIELDS.ADDRESS_KEY.KEY}
              maxLength={8}
              validate={required}
            >
              {props => (
                <AddressLookup
                  {...props.input}
                  onChange={fullAddress => {
                    // Use unique Address Key value from the full address for the field
                    props.input.onChange(fullAddress?.Key);
                  }}
                  label={FIELDS.ADDRESS_KEY.LABEL}
                  variant='filled'
                  fullWidth
                  sx={{ mt: 2 }}
                  countryISO={countryISO}
                />
              )}
            </Field>
            <Button
              sx={{
                mt: 2,
                alignSelf: 'flex-end',
              }}
              type='submit'
              variant='contained'
              size='small'
              color='secondary'
              disabled={submitting || invalid}
            >
              {SEND_NOW}
            </Button>
          </Box>
        )}
      />
    </Box>
  );
};

export default QuoteForm;
