import { useCallback, useEffect, useState } from 'react';
import { Box, Button, Grid, useTheme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { cloneDeep, get, round } from 'lodash';

import { format } from 'date-fns';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { PAYMENT } from '../../../../constants/analytics';
import PurchaseWidget from '../../../../components/PurchaseWidget/PurchaseWidget';
import useAnalytics from '../../../../utils/useAnalytics';
import { SummaryDetails } from '../../../../components/SummaryDetails/index';
import SalesConditions from '../../../../components/SalesConditions/index';
import InformationCollecting from '../../../../components/InformationCollecting';
import { BACK, VIEW_CART } from '../../../../constants/strings';
import { SHIPMENT_FIELDS } from '../../../../constants/forms';
import * as OrderSelectors from '../../selectors';
import { BasketActions, BasketSelectors } from '../../../../redux/basket';
import configSlice from '../../../../redux/configSlice';
import { useOverlay } from '../../../../features/Overlay';
import { useCustomSnackbar } from '../../../../features/CustomSnackbar';
import Voucher from '../../../../features/Voucher';
import useBreakpoint from '../../../../hooks/useBreakpoint';
import ParcelInsuranceSection from '../../../../components/ParcelInsuranse';
import PaymentCard from './components/PaymentCard';
import * as StepSelectors from './selectors';

const Payment = ({
  previousStep,
  values,
  references,
  setValues,
  setReferences,
}) => {
  const [acceptTerms, setAcceptTerms] = useState(false);
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const overlay = useOverlay();
  const snackbar = useCustomSnackbar();
  const isSmallScreen = useBreakpoint('md');

  const basketId = useSelector(BasketSelectors.getBasketId);
  const threeDSecure = useSelector(StepSelectors.getThreeDSecureData);
  const price = useSelector(OrderSelectors.getTotalAmount);

  const totalPriceValue = round(
    values.outboundConsignment?.parcels.reduce(
      (totalValue, parcel) =>
        totalValue + Number(parcel._value) * Number(parcel._quantity),
      0
    )
  );

  const {
    ui: {
      links: { terms },
    },
  } = useSelector(configSlice.selectors.getConfig);

  const { Trackable, trackAction } = useAnalytics();

  useEffect(() => {
    const newValues = {
      ...values,
      _valid: true, // consider temporary on that step shipment is always valid
    };

    setValues(newValues);

    const basketItemId = searchParams.get('basketItemId');
    dispatch(
      BasketActions.updateBasketItem({
        basketItemId: basketItemId,
        shipment: newValues,
      })
    ).unwrap();
  }, []);

  const setVoucher = useCallback(voucher => {
    setReferences('voucher', voucher);
  }, []);

  const handleOnchangeInsurance = useCallback(
    liabilityValue => {
      const newValues = cloneDeep(values);
      newValues.outboundConsignment.liabilityValue = liabilityValue;
      setValues(newValues);
    },
    [values]
  );

  const handleCheckoutSubmit = useCallback(
    async payload => {
      try {
        overlay.show();
        const basketItemId = searchParams.get('basketItemId');
        const purchaseData = {
          amount: price.totalIncVatAmount,
          diaryDate: format(new Date(), 'dd/MM/yyyy'),
          paymentNonce: payload.nonce,
          vendorRef: payload.type,
          contactName: get(values, SHIPMENT_FIELDS.COLLECTION_CONTACT_NAME.KEY),
          contactEmail: get(
            values,
            SHIPMENT_FIELDS.COLLECTION_CONTACT_EMAIL.KEY
          ),
          contactPhone: get(
            values,
            SHIPMENT_FIELDS.COLLECTION_CONTACT_TELEPHONE.KEY
          ),
          voucherId: references.voucher && references.voucher.voucherId,
        };
        const {
          shipments: [shipment],
        } = await dispatch(
          BasketActions.checkout({
            basketItemIds: [basketItemId],
            purchaseData,
          })
        ).unwrap();

        snackbar.showSuccess({
          // put correct message later
          message: 'Shipment have been successfully created',
        });

        // navigate to shipments/:shipmentId
        navigate(`/shipments/${shipment.shipmentId}`, {
          replace: true,
        });
      } catch (error) {
        if (!error.details) {
          error.details = [error];
        }
        error.details.forEach(detail => {
          snackbar.showError({
            autoHideDuration: null,
            message: detail.message,
          });
        });
      } finally {
        overlay.hide();
      }
    },
    [overlay, searchParams, dispatch, basketId, snackbar, navigate]
  );

  const handleBack = useCallback(
    event => {
      trackAction(event);
      previousStep();
    },
    [previousStep, trackAction]
  );

  const VoucherComponent = useCallback(
    sx => (
      <Box sx={sx}>
        <Voucher voucher={references.voucher} setVoucher={setVoucher} />
      </Box>
    ),
    [references.voucher]
  );

  return (
    <Trackable loadId={PAYMENT.LOAD} interfaceId={PAYMENT.INTERFACE_ID}>
      <Grid container spacing={2} sx={{ mt: 0 }}>
        <Grid item xs={12} md={8}>
          <PaymentCard shipment={values} references={references} />
          {isSmallScreen && VoucherComponent({ mt: 2 })}
          <Grid item xs={12} sx={{ mt: 2 }}>
            <ParcelInsuranceSection
              parcelValue={totalPriceValue}
              selectedInsuranceValue={
                values.outboundConsignment?.liabilityValue
              }
              onChangeInsurance={handleOnchangeInsurance}
            />
            <SalesConditions
              onAcceptTerms={setAcceptTerms}
              salesConditionsLink={terms}
            />
            <InformationCollecting
              sx={{
                px: { xs: 3, md: 0 },
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box sx={{ position: 'sticky', top: theme.spacing(2) }}>
            <Box
              sx={{
                pl: { xs: 3, md: 0 },
                pr: { xs: 3, md: 0 },
              }}
            >
              <SummaryDetails
                price={price}
                basketItemsWithPrice={[{ shipment: values, price }]}
              />
              {!isSmallScreen && VoucherComponent()}
              <Box>
                <Box
                  sx={{
                    gap: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                    pb: 2,
                  }}
                >
                  <Button
                    actionid={PAYMENT.CLICK_BACK}
                    variant='outlined'
                    onClick={handleBack}
                    sx={{ width: '100%' }}
                  >
                    {BACK}
                  </Button>
                  <Button
                    actionid={PAYMENT.CLICK_VIEW_CART}
                    variant='outlined'
                    onClick={() => {
                      navigate({
                        pathname: '/basket',
                        search:
                          references.voucher?.voucherId &&
                          createSearchParams({
                            voucherId: references.voucher?.voucherId,
                          }).toString(),
                      });
                    }}
                    sx={{ width: '100%' }}
                  >
                    {VIEW_CART}
                  </Button>
                </Box>
              </Box>
              <PurchaseWidget
                basketId={basketId}
                threeDSecure={threeDSecure}
                amount={price.totalIncVatAmount.toString()}
                handleCheckout={handleCheckoutSubmit}
                sx={{ width: '100%' }}
                disabled={!basketId || !acceptTerms}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Trackable>
  );
};

export default Payment;
