import { createAsyncThunk } from '@reduxjs/toolkit';
import { getToken } from 'firebase/app-check';
import * as Auth from 'firebase/auth';

import { appCheck, firebaseAuth } from '../../firebase';
import { authApis } from '../../apis';
import { wrapAuthHandler } from './utils';

const signInWithCustomTokenFirebase = wrapAuthHandler(
  Auth.signInWithCustomToken
);

export const sendMagicLink = createAsyncThunk(
  'auth/sendMagicLink',
  async (email, { rejectWithValue }) => {
    try {
      const { token } = await getToken(appCheck);
      return await authApis.sendMagicLink(email, token);
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        throw error;
      }
    }
  }
);

export const verifyMagicLink = createAsyncThunk(
  'auth/verifyMagicLink',
  async (verificationId, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await authApis.verifyMagicLink(verificationId);
      return await dispatch(signInWithCustomToken(data.customToken)).unwrap();
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        throw error;
      }
    }
  }
);

export const createSession = createAsyncThunk(
  'auth/createSession',
  async token => (await authApis.login(token)).data
);

export const logoutSession = createAsyncThunk(
  'auth/logoutSession',
  async () => (await authApis.logout()).data
);

export const getCurrentSession = createAsyncThunk(
  'auth/getCurrentSession',
  async (_, { rejectWithValue }) => {
    try {
      const result = await authApis.getSession();
      return result.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        throw error;
      }
    }
  }
);

export const signInWithCustomToken = createAsyncThunk(
  'auth/signInWithCustomToken',
  async (customToken, { dispatch }) => {
    const userCredential = await signInWithCustomTokenFirebase(
      firebaseAuth,
      customToken
    );
    const token = await userCredential.user.getIdToken();
    return dispatch(createSession(token)).unwrap();
  }
);

export const verifyPhoneNumber = createAsyncThunk(
  'auth/verifyPhoneNumber',
  async (phoneNumber, { rejectWithValue }) => {
    try {
      const { token } = await getToken(appCheck);
      const result = await authApis.verifyPhoneNumber(phoneNumber, token);
      return result.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        throw error;
      }
    }
  }
);

export const replicateConsumerUser = createAsyncThunk(
  'auth/replicateConsumerUser',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await authApis.replicateConsumerUser();
      return await dispatch(signInWithCustomToken(data.customToken)).unwrap();
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        throw error;
      }
    }
  }
);

export const verifyPin = createAsyncThunk(
  'auth/verifyPin',
  async ({ verificationId, pin }, { rejectWithValue }) => {
    try {
      const result = await authApis.verifyPin(verificationId, pin);
      return result.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        throw error;
      }
    }
  }
);
