import { createSelector } from '@reduxjs/toolkit';

import { OrderSliceSelectors } from '../../slice';
import { SHIPMENT_FIELDS } from '../../../../constants/forms';
import { getInitialValues, getInvoiceAvailableFields } from './helpers';

export const getInitialFormValues = createSelector(
  OrderSliceSelectors.getValues,
  getInitialValues
);

export const getRequiredFields = createSelector(
  [OrderSliceSelectors.getValues],
  () => [
    SHIPMENT_FIELDS.COLLECTION_CONTACT_NAME.KEY,
    SHIPMENT_FIELDS.COLLECTION_CONTACT_EMAIL.KEY,
    SHIPMENT_FIELDS.COLLECTION_CONTACT_TELEPHONE.KEY,
    SHIPMENT_FIELDS.DELIVERY_CONTACT_NAME.KEY,
    SHIPMENT_FIELDS.DELIVERY_CONTACT_EMAIL.KEY,
    SHIPMENT_FIELDS.DELIVERY_CONTACT_MOBILE.KEY,
  ]
);

export const getAvailableFields = createSelector(
  OrderSliceSelectors.getValues,
  getInvoiceAvailableFields
);
