import Box from '@mui/material/Box';
import { Button, Divider, Grid, styled, Typography } from '@mui/material';
import { Form } from 'react-final-form';
import { TextField as TextFieldRFF } from 'mui-rff';
import { useDispatch, useSelector } from 'react-redux';
import { ACCOUNT_DETAILS_SECTION } from '../../../../constants/strings';
import {
  getAccountDetailsFields,
  isAccountDetailsLoading,
  updateAccountDetails,
} from '../../../../redux/userSlice';
import { useCustomSnackbar } from '../../../../features/CustomSnackbar';
import {
  accountDetailsSchema,
  validateWithJoi,
} from '../../../../utils/validators';
import { ACCOUNT_DETAILS_FIELDS } from '../../../../constants/forms';
import { fieldTrimOnBlur } from '../../../../utils/formHelpers';
import { StringUtil } from '../../../../utils';
import AccountSectionLayout from './AccountSectionLayout';

const StyledTextField = styled(TextFieldRFF)(() => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px',
  },
}));

const AccountDetails = () => {
  const dispatch = useDispatch();
  const { showError, showDefault } = useCustomSnackbar();
  const accountDetailsFields = useSelector(state =>
    getAccountDetailsFields(state)
  );
  const accountDetailsUpdateLoading = useSelector(state =>
    isAccountDetailsLoading(state)
  );

  const onSubmit = async values => {
    try {
      await dispatch(updateAccountDetails(values)).unwrap();
      showDefault({
        message: ACCOUNT_DETAILS_SECTION.SNACKBAR_MESSAGES.SUCCESS,
      });
    } catch (e) {
      showError({ message: ACCOUNT_DETAILS_SECTION.SNACKBAR_MESSAGES.FAILED });
    }
  };

  return (
    <AccountSectionLayout>
      <Form
        onSubmit={onSubmit}
        validate={values => validateWithJoi(values, accountDetailsSchema)}
        initialValues={accountDetailsFields}
        render={({ handleSubmit, form, invalid, pristine }) => (
          <form onSubmit={handleSubmit}>
            <Box sx={{ paddingX: 2, paddingTop: 2, paddingBottom: 1 }}>
              <Typography
                variant='typography/subtitle2'
                sx={{
                  fontSize: '16px',
                  fontWeight: 400,
                  lineHeight: '158%',
                  letterSpacing: '0.1px',
                }}
              >
                {ACCOUNT_DETAILS_SECTION.ACCOUNT_DETAILS}
              </Typography>
            </Box>
            <Divider />
            <Box sx={{ padding: 2 }}>
              <Grid container columnSpacing={1}>
                <Grid item mb={2} xs={12} md={6}>
                  <StyledTextField
                    name={
                      ACCOUNT_DETAILS_FIELDS.ACCOUNT_DETAILS.FIRST_NAME.NAME
                    }
                    data-testid={
                      ACCOUNT_DETAILS_FIELDS.ACCOUNT_DETAILS.FIRST_NAME.NAME
                    }
                    variant='outlined'
                    fullWidth
                    required
                    label={
                      ACCOUNT_DETAILS_FIELDS.ACCOUNT_DETAILS.FIRST_NAME.LABEL
                    }
                    fieldProps={{
                      ...fieldTrimOnBlur,
                      parse: StringUtil.trimFirstSpace,
                    }}
                  />
                </Grid>
                <Grid item mb={2} xs={12} md={6}>
                  <StyledTextField
                    name={ACCOUNT_DETAILS_FIELDS.ACCOUNT_DETAILS.LAST_NAME.NAME}
                    data-testid={
                      ACCOUNT_DETAILS_FIELDS.ACCOUNT_DETAILS.LAST_NAME.NAME
                    }
                    label={
                      ACCOUNT_DETAILS_FIELDS.ACCOUNT_DETAILS.LAST_NAME.LABEL
                    }
                    variant='outlined'
                    fullWidth
                    required
                    fieldProps={{
                      ...fieldTrimOnBlur,
                      parse: StringUtil.trimFirstSpace,
                    }}
                  />
                </Grid>
                <Grid item mb={2} xs={12} md={6}>
                  <StyledTextField
                    name={
                      ACCOUNT_DETAILS_FIELDS.ACCOUNT_DETAILS.EMAIL_ADDRESS.NAME
                    }
                    data-testid={
                      ACCOUNT_DETAILS_FIELDS.ACCOUNT_DETAILS.EMAIL_ADDRESS.NAME
                    }
                    label={
                      ACCOUNT_DETAILS_FIELDS.ACCOUNT_DETAILS.EMAIL_ADDRESS.LABEL
                    }
                    variant='outlined'
                    fullWidth
                    required
                    fieldProps={{
                      ...fieldTrimOnBlur,
                      parse: StringUtil.trimFirstSpace,
                    }}
                  />
                </Grid>
                <Grid item mb={2} xs={12} md={6}>
                  <StyledTextField
                    name={
                      ACCOUNT_DETAILS_FIELDS.ACCOUNT_DETAILS.PHONE_NUMBER.NAME
                    }
                    data-testid={
                      ACCOUNT_DETAILS_FIELDS.ACCOUNT_DETAILS.PHONE_NUMBER.NAME
                    }
                    label={
                      ACCOUNT_DETAILS_FIELDS.ACCOUNT_DETAILS.PHONE_NUMBER.LABEL
                    }
                    variant='outlined'
                    fullWidth
                    fieldProps={{
                      ...fieldTrimOnBlur,
                      parse: StringUtil.trimFirstSpace,
                    }}
                  />
                </Grid>
                <Grid item mb={2} xs={12} md={6}>
                  <StyledTextField
                    name={
                      ACCOUNT_DETAILS_FIELDS.ACCOUNT_DETAILS.COMPANY_NAME.NAME
                    }
                    data-testid={
                      ACCOUNT_DETAILS_FIELDS.ACCOUNT_DETAILS.COMPANY_NAME.NAME
                    }
                    label={
                      ACCOUNT_DETAILS_FIELDS.ACCOUNT_DETAILS.COMPANY_NAME.LABEL
                    }
                    variant='outlined'
                    fullWidth
                    fieldProps={{
                      ...fieldTrimOnBlur,
                      parse: StringUtil.trimFirstSpace,
                    }}
                  />
                </Grid>
                <Grid item mb={2} xs={12} md={6}>
                  <StyledTextField
                    name={
                      ACCOUNT_DETAILS_FIELDS.ACCOUNT_DETAILS.VAT_NUMBER.NAME
                    }
                    data-testid={
                      ACCOUNT_DETAILS_FIELDS.ACCOUNT_DETAILS.VAT_NUMBER.NAME
                    }
                    label={
                      ACCOUNT_DETAILS_FIELDS.ACCOUNT_DETAILS.VAT_NUMBER.LABEL
                    }
                    variant='outlined'
                    fullWidth
                    fieldProps={{
                      ...fieldTrimOnBlur,
                      parse: StringUtil.trimFirstSpace,
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
            <Divider />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                data-testid={'saveButton'}
                disabled={invalid || accountDetailsUpdateLoading || pristine}
                variant={'outlined'}
                sx={{
                  marginX: 2,
                  marginTop: 1,
                  marginBottom: 2,
                }}
                onClick={handleSubmit}
              >
                {ACCOUNT_DETAILS_SECTION.SAVE}
              </Button>
            </Box>
          </form>
        )}
      />
    </AccountSectionLayout>
  );
};

export default AccountDetails;
