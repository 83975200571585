import { chain, cloneDeep, get, set } from 'lodash';
import { SHIPMENT_FIELDS } from '../../../../constants/forms';
import { ShipmentHelper } from '../../helpers';
import { ShipmentHelpers as BaseHelpers } from '../../../../helpers';
import * as STRINGS from '../../../../constants/strings';

export const getInitialValues = values => {
  const clonedValues = cloneDeep(values);
  return chain(clonedValues)
    .set(
      SHIPMENT_FIELDS.SENDER_USER_TYPE.KEY,
      get(values, SHIPMENT_FIELDS.SENDER_USER_TYPE.KEY) ||
        ShipmentHelper.getSenderUserType(values)
    )
    .set(
      SHIPMENT_FIELDS.RECEIVER_USER_TYPE.KEY,
      get(values, SHIPMENT_FIELDS.RECEIVER_USER_TYPE.KEY) ||
        ShipmentHelper.getReceiverUserType(values)
    )
    .set(
      SHIPMENT_FIELDS.DELIVERY_CONTACT_NAME.KEY,
      get(values, SHIPMENT_FIELDS.DELIVERY_CONTACT_NAME.KEY) || ''
    )
    .set(
      SHIPMENT_FIELDS.DELIVERY_CONTACT_EMAIL.KEY,
      get(values, SHIPMENT_FIELDS.DELIVERY_CONTACT_EMAIL.KEY) || ''
    )
    .set(
      SHIPMENT_FIELDS.DELIVERY_CONTACT_MOBILE.KEY,
      get(values, SHIPMENT_FIELDS.DELIVERY_CONTACT_MOBILE.KEY) || ''
    )
    .thru(result =>
      BaseHelpers.isInvoiceAvailable(values)
        ? set(result, SHIPMENT_FIELDS.CURRENCY.KEY, STRINGS.GBP)
        : result
    )
    .value();
};

export const getInvoiceAvailableFields = values => {
  const isInvoiceAvailable = BaseHelpers.isInvoiceAvailable(values);

  return {
    isInvoiceAvailable,
    [SHIPMENT_FIELDS.INVOICE_EXPORTER_EORI_NUMBER.KEY]: isInvoiceAvailable,
    [SHIPMENT_FIELDS.INVOICE_EXPORTER_VAT_NUMBER.KEY]: isInvoiceAvailable,
    // TODO: continue
    [SHIPMENT_FIELDS.INVOICE_EXPORTER_UKIMS_NUMBER.KEY]: isInvoiceAvailable,
  };
};
