import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import QuoteForm from '../../components/QuoteForm';
import bgMain from '../../assets/images/homeBgMain.png';
import bgMainMobile from '../../assets/images/homeBgMainMobile.png';
import homeImg1 from '../../assets/images/homeImg1.png';
import homeImg2 from '../../assets/images/homeImg2.png';
import homeImg3 from '../../assets/images/homeImg3.png';
import {
  BULK_SHIPPING_DESCRIPTION,
  BULK_SHIPPING_HEADER,
  EASY_INTENATIONAL_SHIPPING,
  FREE_LABEL_PRINTING_DESCRIPTION,
  GO_GLOBAL,
  INTERNATION_SHIPPING_DESCRIPTION,
  LABEL_FREE_DROP_OFF,
  MULTI_PARCEL_SHIPPING,
  NO_PRINTER_NO_PROBLEM,
  SEND_PARCEL,
  SEND_PARCEL_DESCRIPTION,
  SHIP_PRICE_FROM,
  SUPPORTED_PLATFORMS,
  SUPPORTED_PLATFORMS_DESCRIPTION,
} from '../../constants/strings';
import useAnalytics from '../../utils/useAnalytics';
import { HOME } from '../../constants/analytics';
import {
  countriesActions,
  countriesSelectors,
} from '../../redux/countriesSlice';
import { FIELDS } from '../../constants/forms';

function Home() {
  const navigate = useNavigate();
  const { setInterfaceId, Trackable } = useAnalytics();
  const dispatch = useDispatch();
  const countries = useSelector(countriesSelectors.getCountries);

  useEffect(() => {
    setInterfaceId(HOME.INTERFACE_ID);
  }, []);

  const handleQuoteFormSubmit = useCallback(
    values => {
      navigate(
        `/quotes?${FIELDS.DESTINATION_COUNTRY.KEY}=${
          values[FIELDS.DESTINATION_COUNTRY.KEY]
        }&${FIELDS.ADDRESS_KEY.KEY}=${values[FIELDS.ADDRESS_KEY.KEY]}`
      );
    },
    [navigate]
  );

  return (
    <Trackable loadId={HOME.LOAD}>
      <Box sx={{ flexGrow: 1 }}>
        <Box
          sx={{
            backgroundImage: {
              xs: `url(${bgMainMobile})`,
              sm: `url(${bgMain})`,
            },
            backgroundSize: { xs: 'cover', md: 'contain' },
            backgroundRepeat: 'no-repeat',
            width: '100%',
            height: 0,
            paddingTop: {
              xs: 'calc(639 / 390 * 100%)',
              sm: '700px',
              md: 'calc(500 / 1200 * 100%)',
            },
            position: 'relative',
            backgroundPosition: { sm: '50%', md: 'center' },
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
            }}
          >
            <Box
              sx={{
                backgroundColor: 'primary.main',
                pt: '34px',
                pl: 5,
                pb: '28px',
                borderTopRightRadius: 64,
              }}
            >
              <Typography variant='h1' sx={{ color: 'primary.contrastText' }}>
                {SEND_PARCEL}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex' }}>
              <Box sx={{ height: '8px', width: '262px' }} />
              <Box sx={{ flexGrow: 1, backgroundColor: 'primary.main' }} />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                gap: 4,
                backgroundColor: 'primary.main',
                px: 5,
                pb: '34px',
                pt: '28px',
              }}
            >
              <Box sx={{ width: '245px', mr: 2 }}>
                <Typography variant='h3' sx={{ color: 'primary.contrastText' }}>
                  {SHIP_PRICE_FROM}
                </Typography>
                <Typography sx={{ color: 'primary.contrastText' }}>
                  {SEND_PARCEL_DESCRIPTION}
                </Typography>
              </Box>
              <QuoteForm
                countries={countries}
                onSubmit={handleQuoteFormSubmit}
              />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            mt: { md: 5 },
            display: 'flex',
            gap: { xs: 2, md: 5 },
            flexDirection: { xs: 'column', md: 'row' },
          }}
        >
          <Box
            sx={{
              px: { xs: 2, md: 0 },
              pl: { md: 5 },
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <Typography variant='h2' sx={{ py: 2 }}>
              {EASY_INTENATIONAL_SHIPPING}
            </Typography>
            <Typography variant='h3'>{GO_GLOBAL}</Typography>
            <Typography>{INTERNATION_SHIPPING_DESCRIPTION}</Typography>
          </Box>
          <Box
            sx={{
              px: { xs: 2, md: 0 },
              display: { xs: 'flex', md: 'block' },
              justifyContent: { xs: 'center', md: 'flex-end' },
              width: '100%',
            }}
          >
            <img src={homeImg1} style={{ width: '100%' }} />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: 4,
            mt: '20px',
            flexDirection: { xs: 'column-reverse', md: 'row' },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
              px: { xs: 2, md: 0 },
              pl: { md: '120px' },
              flex: '1 1 0px',
            }}
          >
            <img src={homeImg2} style={{ width: '100%' }} />
          </Box>
          <Box sx={{ width: '100%', px: { xs: 2, md: 0 }, flex: '1 1 0px' }}>
            <Box
              sx={{
                backgroundColor: 'primary.main',
                width: '100%',
                height: '100%',
                borderBottomLeftRadius: 64,
                py: { xs: 2, md: 3 },
                px: 4,
              }}
            >
              <Typography
                variant='h2'
                sx={{ color: 'primary.contrastText', mb: { xs: 1, md: 3 } }}
              >
                {LABEL_FREE_DROP_OFF}
              </Typography>
              <Box
                sx={{
                  width: { xs: '292px', md: '383px' },
                  height: '8px',
                  backgroundColor: 'primary.contrastText', // TODO: NO VALUE IN THE THEME OVERRIDES YET
                  ml: '-32px',
                }}
              />
              <Typography
                variant='h3'
                sx={{ pt: { xs: 2, md: 4 }, color: 'primary.contrastText' }}
              >
                {NO_PRINTER_NO_PROBLEM}
              </Typography>
              <Typography sx={{ color: 'primary.contrastText' }}>
                {FREE_LABEL_PRINTING_DESCRIPTION}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: 4,
            mt: 4,
            flexDirection: { xs: 'column-reverse', md: 'row' },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
              px: { xs: 2, md: 0 },
              pl: { md: '120px' },
              flex: '1 1 0px',
            }}
          >
            <img src={homeImg3} style={{ width: '100%', height: '100%' }} />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              width: '100%',
              flex: '1 1 0px',
            }}
          >
            <Box sx={{ px: { xs: 2, md: 5 } }}>
              <Typography variant='h2' sx={{ py: 2 }}>
                {EASY_INTENATIONAL_SHIPPING}
              </Typography>
              <Typography variant='h3'>{GO_GLOBAL}</Typography>
              <Typography>{INTERNATION_SHIPPING_DESCRIPTION}</Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={{ mt: 4, pr: { xs: '16px', md: '80px' }, mb: '124px' }}>
          <Box
            sx={{
              backgroundColor: 'primary.main',
              py: { xs: 3, md: '45px' },
              px: { xs: 4, md: '50px' },
              borderBottomRightRadius: { xs: '64px', md: '115px' },
            }}
          >
            <Typography variant='h2' sx={{ color: 'primary.contrastText' }}>
              {MULTI_PARCEL_SHIPPING}
            </Typography>
            <Box
              sx={{
                width: '373px',
                height: '16px',
                backgroundColor: 'primary.contrastText',
                ml: '-50px',
                mt: '42px',
                display: { xs: 'none', md: 'block' },
              }}
            />
            <Box
              display='flex'
              sx={{
                mt: { xs: 2, md: '110px' },
                flexDirection: { xs: 'column', md: 'row' },
              }}
            >
              <Box sx={{ pr: '100px' }}>
                <Typography variant='h3' sx={{ color: 'primary.contrastText' }}>
                  {BULK_SHIPPING_HEADER}
                </Typography>
                <Typography
                  sx={{
                    color: 'primary.contrastText',
                    width: { md: '506px' },
                    mt: { xs: 2, md: 0 },
                  }}
                >
                  {BULK_SHIPPING_DESCRIPTION}
                </Typography>
              </Box>
              <Box
                sx={{
                  backgroundColor: 'primary.contrastText',
                  width: '16px',
                  minWidth: '16px',
                  mb: '-45px',
                  display: { xs: 'none', md: 'block' },
                }}
              />
              <Box sx={{ pl: { md: '60px' } }}>
                <Typography
                  variant='h3'
                  sx={{ color: 'primary.contrastText', mt: { xs: 2, md: 0 } }}
                >
                  {SUPPORTED_PLATFORMS}
                </Typography>
                <Typography
                  sx={{ color: 'primary.contrastText', mt: { xs: 2, md: 0 } }}
                >
                  {SUPPORTED_PLATFORMS_DESCRIPTION}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Trackable>
  );
}

export default Home;
