export const REFERENCE_NAME = {
  COLLECTION_DATES: 'collectionDates',
  COLLECTION_PICKUP: 'collectionPickup',
  COLLECTION_ADDRESS: 'collectionAddress',
  DELIVERY_PICKUP: 'deliveryPickup',
  DELIVERY_ADDRESS: 'deliveryAddress',
};

export const USER_TYPE = {
  INDIVIDUAL: 'individual',
  COMPANY: 'company',
};

export const USER_TYPES = [
  { label: 'Individual', value: USER_TYPE.INDIVIDUAL },
  { label: 'Company', value: USER_TYPE.COMPANY },
];

export const FREE_PROTECTION_DEDUCTION = 50;

export const INSURANCE_SECTION = {
  TITLE: coveredPrice => `Cover up to £${coveredPrice}`,
  DESCRIPTION: (coveredPrice, additionalPrice) =>
    additionalPrice !== 0
      ? `Your delivery will be insured up to £${coveredPrice}`
      : `Your delivery is automatically insured up to £${coveredPrice}`,
  PRICE: additionalPrice =>
    additionalPrice !== 0 ? `+${additionalPrice.toFixed(2)}` : 'Free',
};

export const DEFAULT_BOOLEAN_KEY_VALUE = [
  {
    label: 'Yes',
    value: '1',
  },
  {
    label: 'No',
    value: '0',
  },
];

export const CURRENCY_SYMBOLS = {
  GBP: '£',
  EUR: '€',
  USD: '$',
};
