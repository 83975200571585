import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  Box,
  Button,
  IconButton,
  Modal,
  Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import BraintreeWebDropIn from 'braintree-web-drop-in';
import useAnalytics from '../../utils/useAnalytics';
import { PAYMENT } from '../../constants/analytics';
import { PURCHASE_WIDGET } from '../../constants/strings';
import Debugger from '../Debugger';
import { paymentApis } from '../../apis';
import Sentry from '../../sentry';
import { useOverlay } from '../../features/Overlay';
const DROPIN_CONTAINER_ID = 'dropin-container';

// TODO: Remove test data
const testData = `TEST data
Number (successful with no challenge): 4000000000001000
Number (successful with challenge): 4000000000001091
Number (unsuccessful with challenge): 4000000000001109
Expiration Date: 06/27
CVV: 123
Postal Code: 12345`;

const PurchaseWidget = ({
  handleCheckout,
  sx,
  basketId,
  amount,
  threeDSecure,
  disabled = true,
  children = PURCHASE_WIDGET.CHECKOUT,
}) => {
  const { trackAction } = useAnalytics();
  const [braintreeInstance, setBraintreeInstance] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [nonce, setNonce] = useState();
  const [liabilityShift, setLiabilityShift] = useState();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const overlay = useOverlay();

  const errorHandler = (message, error) => {
    setErrorMessage(message);

    Sentry.captureException(error, { component: 'PurchaseWidget', message });
  };

  const validateForm = fields => {
    const fieldKeys = Object.keys(fields);
    let isFormValid = true;

    // Check validity
    fieldKeys.forEach(key => {
      if (!fields[key].isValid) {
        isFormValid = false;
      }
    });

    if (isFormValid) {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  };

  const subscriptions = [
    {
      'card:focus': () => {
        setErrorMessage('');
      },
    },
    {
      'card:validityChange': event => {
        validateForm(event.fields);
      },
    },
    {
      changeActiveView: event => {
        const { newViewId } = event;

        const changeActiveViewActionsMap = {
          // Disable submit button on Options view
          options: () => {
            setIsSubmitDisabled(true);
            setErrorMessage('');
          },
          paypal: () => setIsSubmitDisabled(true),

          // Enable submit button when methods are available
          methods: () => setIsSubmitDisabled(false),
          card: () => setIsSubmitDisabled(true),
        };

        // Do a view action if available
        if (changeActiveViewActionsMap[newViewId]) {
          changeActiveViewActionsMap[newViewId]();
        }
      },
    },
  ];

  const setupDropIn = async () => {
    overlay.show();
    try {
      const clientToken = await paymentApis.getClientToken(basketId);

      BraintreeWebDropIn.create(
        {
          authorization: clientToken,
          container: `#${DROPIN_CONTAINER_ID}`,
          preselectVaultedPaymentMethod: true,
          threeDSecure: true,
          paypal: {
            flow: 'checkout',
            amount,
            currency: PURCHASE_WIDGET.PAYMENT_CURRENCY_CODE,
          },
          applePay: {
            displayName: PURCHASE_WIDGET.MERCHANT_NAME,
            paymentRequest: {
              total: {
                label: PURCHASE_WIDGET.MERCHANT_NAME,
                amount,
              },
            },
          },
        },
        (error, instance) => {
          overlay.hide();
          if (instance) {
            setBraintreeInstance(instance);

            // Subscriptions handling.
            // Details: https://braintree.github.io/braintree-web-drop-in/docs/current/Dropin.html#on
            subscriptions.forEach(item => {
              const eventName = Object.keys(item)[0];
              const callBack = item[eventName];
              instance.on(eventName, callBack);
            });
          }

          if (error) {
            errorHandler(PURCHASE_WIDGET.WIDGET_CREATE_ERROR, error);
          }
        }
      );
    } catch (error) {
      overlay.hide();
      errorHandler(PURCHASE_WIDGET.CLIENT_TOKEN_ERROR, error);
    }
  };

  const onCheckoutClick = event => {
    trackAction(event);
    setModalOpen(true);
    setupDropIn();
  };

  const onSubmitClick = event => {
    setErrorMessage('');
    setIsSubmitDisabled(true);
    trackAction(event);
    braintreeInstance.requestPaymentMethod(
      {
        threeDSecure: {
          amount,
          ...threeDSecure,
        },
      },
      (error, payload) => {
        if (error) {
          errorHandler(PURCHASE_WIDGET.TOKENIZATION_ERROR, error);
          setIsSubmitDisabled(true);

          return;
        }

        // 3D Secure liabilityShifted should be true if it is possible
        // It is not possible for PayPal balance (skip liabilityShifted check)
        if (payload.liabilityShiftPossible && !payload.liabilityShifted) {
          errorHandler(PURCHASE_WIDGET.LIABILITY_ERROR, payload);
          showNonce(payload, false);
          return;
        }
        showNonce(payload, true);
        return handleCheckout(payload)
          .catch(err => {
            errorHandler(PURCHASE_WIDGET.PAYMENT_ERROR, err);
          })
          .finally(closeModal);
      }
    );
  };

  const showNonce = (payload, liabilityShift) => {
    setNonce(payload.nonce);
    setLiabilityShift(liabilityShift);
  };

  const closeModal = async () => {
    if (braintreeInstance) {
      // Unsubscribe events handling
      subscriptions.forEach(item => {
        braintreeInstance.off(item.eventName, item.callBack);
      });

      braintreeInstance.teardown();
    }

    setModalOpen(false);
    setErrorMessage('');
    setIsSubmitDisabled(true);
  };

  return (
    <>
      <Modal open={modalOpen}>
        <Box
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            bgcolor: 'primary.contrastText',
            m: { sm: '10% auto' },
            borderRadius: 1,
            maxWidth: { sm: 600 },
            height: {
              xs: '100%',
              sm: 'auto',
            },
            overflowY: {
              xs: 'scroll',
              sm: 'auto',
            },
            position: 'relative',
          }}
        >
          <IconButton
            aria-label='close'
            sx={{ position: 'absolute', right: 5, top: 5, zIndex: 1 }}
          >
            <Close onClick={() => setModalOpen(false)} />
          </IconButton>

          <Debugger>
            {/* TODO: Remove after testing full implementation */}
            <pre>{testData}</pre>
            {nonce ? (
              <div>
                <Typography>Payment method nonce received:</Typography>
                <Typography>
                  Liability shifted: {liabilityShift.toString()}
                </Typography>
                <Typography>Nonce: {nonce}</Typography>
              </div>
            ) : null}
          </Debugger>
          <Box id={DROPIN_CONTAINER_ID} sx={{ mb: 2 }}></Box>
          {errorMessage ? (
            <Alert severity='error' sx={{ mb: 2 }}>
              {errorMessage}
            </Alert>
          ) : null}

          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}>
            <Button
              actionid={PAYMENT.CLICK_CLOSE}
              onClick={closeModal}
              variant='contained'
              color='primary'
            >
              {PURCHASE_WIDGET.CLOSE}
            </Button>
            <Button
              actionid={PAYMENT.CLICK_SUBMIT}
              onClick={onSubmitClick}
              variant='contained'
              color='primary'
              disabled={isSubmitDisabled}
            >
              {PURCHASE_WIDGET.SUBMIT}
            </Button>
          </Box>
        </Box>
      </Modal>
      <Button
        actionid={PAYMENT.CLICK_CHECKOUT}
        onClick={onCheckoutClick}
        variant='contained'
        color='primary'
        sx={sx}
        disabled={disabled}
      >
        {children}
      </Button>
    </>
  );
};

PurchaseWidget.propTypes = {
  sx: PropTypes.object,
  handleCheckout: PropTypes.func.isRequired,
  basketId: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
  threeDSecure: PropTypes.shape({
    email: PropTypes.string,
    billingAddress: PropTypes.shape({
      givenName: PropTypes.string,
      surname: PropTypes.string,
      phoneNumber: PropTypes.string,
      streetAddress: PropTypes.string,
      extendedAddress: PropTypes.string,
      locality: PropTypes.string,
      region: PropTypes.string,
      postalCode: PropTypes.string,
      countryCodeAlpha2: PropTypes.string,
    }),
  }).isRequired,
  disabled: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

export default PurchaseWidget;
