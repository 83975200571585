import { filter, get } from 'lodash';
import moment from 'moment-timezone';
import { CURRENCY_SYMBOLS, USER_TYPE } from '../constants';
import { DATE_FORMAT, FORM } from '../../../constants';
import { DateUtil } from '../../../utils';

export const getSenderUserType = shipment => {
  let senderUserType = get(shipment, FORM.SHIPMENT_FIELDS.SENDER_USER_TYPE.KEY);
  if (!senderUserType) {
    const organisation = get(
      shipment,
      'outboundConsignment.collectionDetails.address.organisation'
    );
    return organisation ? USER_TYPE.COMPANY : USER_TYPE.INDIVIDUAL;
  }
  return USER_TYPE.INDIVIDUAL;
};

export const getReceiverUserType = shipment => {
  let receiverUserType = get(
    shipment,
    FORM.SHIPMENT_FIELDS.RECEIVER_USER_TYPE.KEY
  );
  if (!receiverUserType) {
    const organisation = get(
      shipment,
      'outboundConsignment.deliveryDetails.address.organisation'
    );
    return organisation ? USER_TYPE.COMPANY : USER_TYPE.INDIVIDUAL;
  }
  return USER_TYPE.INDIVIDUAL;
};

export const getCutOffDates = dates =>
  filter(dates, date =>
    DateUtil.getUKCurrentMomentTime().isBefore(
      moment.tz(
        `${date.date} ${date.collectionCutOff}`,
        `${DATE_FORMAT.DEFAULT_DATE_FORMAT} ${DATE_FORMAT.HH_MM_TIME_FORMAT}`,
        DATE_FORMAT.EUROPE_LONDON_TIME_ZONE
      )
    )
  );

export const formatShipmentDate = ({ date, collectionCutOff }) =>
  moment
    .tz(
      `${date} ${collectionCutOff}`,
      `${DATE_FORMAT.DEFAULT_DATE_FORMAT} ${DATE_FORMAT.HH_MM_TIME_FORMAT}`,
      DATE_FORMAT.EUROPE_LONDON_TIME_ZONE
    )
    .format(DATE_FORMAT.DATE_TIME_FORMAT);

export const getDefaultShipmentDate = defaultCutOff => {
  const nextWorkingDay = DateUtil.getNextWorkingDay();
  return formatShipmentDate({
    date: nextWorkingDay.format(DATE_FORMAT.DEFAULT_DATE_FORMAT),
    collectionCutOff: defaultCutOff,
  });
};

export const getCurrencySymbol = (value = CURRENCY_SYMBOLS.GBP) =>
  CURRENCY_SYMBOLS[value];
