import { Button, Grid } from '@mui/material';
import { Add, Delete, Edit, Remove } from '@mui/icons-material';
import PropTypes from 'prop-types';

import {
  ADD_TO_CHECKOUT,
  EDIT,
  REMOVE,
  REMOVE_FROM_CHECKOUT,
} from '../../../constants/strings';
import useBreakpoint from '../../../hooks/useBreakpoint';

const BasketCardFooter = ({
  basketItem,
  onRemove,
  isInCheckoutList,
  onRemoveFromCheckout,
  onAddToCheckout,
}) => {
  const isSmallScreen = useBreakpoint('sm');

  return (
    <Grid container sx={{ p: 2 }} xs={12} justifyContent='center'>
      <Grid item display='flex' flexGrow={1} justifyContent='center'>
        <Button
          startIcon={<Edit sx={{ color: 'text.secondary' }} />}
          href={`/order?basketItemId=${basketItem.basketItemId}`}
          sx={{ color: 'text.secondary' }}
        >
          {EDIT}
        </Button>
      </Grid>
      <Grid item display='flex' flexGrow={1} justifyContent='center'>
        <Button
          startIcon={<Delete sx={{ color: 'text.secondary' }} />}
          onClick={onRemove}
          sx={{ color: 'text.secondary' }}
        >
          {REMOVE}
        </Button>
      </Grid>
      {basketItem.shipment._valid && (
        <Grid
          item
          justifyContent='flex-end'
          xs={12}
          md={'auto'}
          sx={{
            flexGrow: { xs: 1, md: 0 },
            marginTop: { xs: 1, md: 0 },
          }}
        >
          <Button
            variant={
              (isSmallScreen ? isInCheckoutList : !isInCheckoutList)
                ? 'outlined'
                : 'contained'
            }
            onClick={() =>
              isInCheckoutList
                ? onRemoveFromCheckout(basketItem.basketItemId)
                : onAddToCheckout(basketItem.basketItemId)
            }
            startIcon={isInCheckoutList ? <Remove /> : <Add />}
            sx={{ width: { xs: '100%', md: '240px' } }}
          >
            {isInCheckoutList ? REMOVE_FROM_CHECKOUT : ADD_TO_CHECKOUT}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

BasketCardFooter.propTypes = {
  basketItem: PropTypes.shape({
    basketItemId: PropTypes.string,
    shipment: PropTypes.object,
  }),
  onRemove: PropTypes.func,
  isInCheckoutList: PropTypes.bool,
  onAddToCheckout: PropTypes.func,
  onRemoveFromCheckout: PropTypes.func,
};

export default BasketCardFooter;
