import { cloneDeep, get } from 'lodash';
import { FORM } from '../constants';
import { REQUIRED_TYPE } from '../constants/shipment';
import { SHIPMENT_FIELDS } from '../constants/forms';
import { AddressUtil } from '../utils';

export const getReceiverContactName = shipment =>
  get(shipment, FORM.SHIPMENT_FIELDS.DELIVERY_CONTACT_NAME.KEY, '');

export const isFlagRequired = (object, path) =>
  get(object, path, '') === REQUIRED_TYPE.MANDATORY;

export const isFlagNeedles = (object, path) =>
  get(object, path, '') === REQUIRED_TYPE.NEEDLESS;

export const isFlagOptional = (object, path) =>
  get(object, path, '') === REQUIRED_TYPE.OPTIONAL;

export const isFlagDescription = (object, path) =>
  get(object, path, '') === REQUIRED_TYPE.DESCRIPTION;

const defaultNavigationSteps = [
  {
    key: 'parcelDetails',
    label: 'Parcel',
  },
  {
    key: 'addressDetails',
    label: 'Details',
  },
  {
    key: 'payment',
    label: 'Payment',
  },
];

const customsDetails = {
  key: 'customsDetails',
  label: 'Customs',
};

export const isInvoiceAvailable = shipment => {
  const requiredTypes = [REQUIRED_TYPE.MANDATORY, REQUIRED_TYPE.OPTIONAL];
  const properties = [
    get(shipment, SHIPMENT_FIELDS.NETWORK_EXPORT_REASON_REQUIRED.KEY, ''),
    get(shipment, SHIPMENT_FIELDS.NETWORK_DT1_AVAILABLE.KEY, ''),
    get(shipment, SHIPMENT_FIELDS.NETWORK_DDP_AVAILABLE.KEY, ''),
    get(shipment, SHIPMENT_FIELDS.NETWORK_FDA_REQUIRED.KEY, ''),
    get(shipment, SHIPMENT_FIELDS.NETWORK_COST_REQUIRED.KEY, ''),
  ];
  const isProformaAvailable = requiredTypes.includes(
    get(shipment, SHIPMENT_FIELDS.NETWORK_PROFORMA.KEY, '')
  );
  const isInvoiceAvailable =
    isFlagRequired(
      shipment,
      SHIPMENT_FIELDS.NETWORK_EXPORT_IMPORT_REQUIRED.KEY
    ) && !isFlagNeedles(shipment, SHIPMENT_FIELDS.NETWORK_EORI_REQUIRED.KEY);

  return (
    isProformaAvailable &&
    (isInvoiceAvailable ||
      properties.includes(REQUIRED_TYPE.MANDATORY) ||
      properties.includes(REQUIRED_TYPE.OPTIONAL))
  );
};

export const getNavigationSteps = shipment => {
  const result = cloneDeep(defaultNavigationSteps);

  if (isInvoiceAvailable(shipment)) {
    result.splice(2, 0, customsDetails);
  }

  return result;
};

export const isShipmentDomestic = shipment =>
  AddressUtil.isCountryDomestic(
    get(shipment, 'outboundConsignment.deliveryDetails.address.countryCode')
  );
