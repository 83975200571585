import { isNil } from 'lodash';
import { createSelector, createStructuredSelector } from 'reselect';

import addressBooksSlice from '../../redux/addressBooksSlice';
import { BasketSelectors } from '../../redux/basket';
import userSlice from '../../redux/userSlice';
import { AddressUtil, Formatters } from '../../utils';
import { FORM } from '../../constants';
import { GB } from '../../constants/strings';

const defaultEmptyAddress = {
  organisation: '',
  // property: deliveryAddress.property, there is no mapping on server side
  street: '',
  locality: '',
  town: '',
  county: '',
  postcode: '',
  countryCode: '',
  [FORM.FIELDS.ADDRESS_KEY.KEY]: '',
};

export const getDeliveryAddress = (
  _state,
  { deliveryCountryCode, deliveryAddress }
) =>
  deliveryAddress
    ? {
        organisation: deliveryAddress.organisation,
        // property: deliveryAddress.property, there is no mapping on server side
        street: deliveryAddress.street,
        locality: deliveryAddress.locality,
        town: deliveryAddress.town,
        county: deliveryAddress.county,
        postcode: deliveryAddress.postcode,
        countryCode: deliveryCountryCode,
        [FORM.FIELDS.ADDRESS_KEY.KEY]: deliveryAddress.key,
        _coordinates: AddressUtil.formatCoordinates(deliveryAddress),
      }
    : defaultEmptyAddress;

export const getDefaultCollectionAddress = createSelector(
  addressBooksSlice.selectors.getDefaultAddressBook,
  (_state, { collectionDetailedAddress }) => collectionDetailedAddress,
  (defaultAddress, collectionDetailedAddress) =>
    // Return only country in case if default address or full address retreive lookup is unavailable
    isNil(defaultAddress) || !collectionDetailedAddress
      ? {
          countryCode: GB,
        }
      : {
          addressId: collectionDetailedAddress.Key,
          countryCode: defaultAddress.countryCode,
          postcode: defaultAddress.postcode,
          county: defaultAddress.addressLine4,
          town: defaultAddress.addressLine3,
          organisation: defaultAddress.organisation,
          street: defaultAddress.addressLine1,
          locality: defaultAddress.addressLine2,
          _coordinates: AddressUtil.formatCoordinates(
            collectionDetailedAddress
          ),
        }
);

export const getDefaultCollectionContactDetails = createSelector(
  addressBooksSlice.selectors.getDefaultAddressBook,
  userSlice.selectors.getAccountDetailsData,
  (defaultAddress, accountDetails) => {
    if (!isNil(defaultAddress)) {
      return {
        _addressBookId: defaultAddress.addressBookId,
        contactName: defaultAddress.contactName,
        telephone: defaultAddress.phoneNumber,
        email: defaultAddress.email,
      };
    }

    if (!isNil(accountDetails)) {
      return {
        _addressBookId: null,
        contactName: Formatters.joinStringsWith(
          [accountDetails.firstName, accountDetails.lastName],
          ''
        ),
        telephone: accountDetails.phone,
        email: accountDetails.email,
      };
    }

    return null;
  }
);

export const getShipmentDto = createStructuredSelector({
  'outboundConsignment.deliveryDetails.address': getDeliveryAddress,
  'outboundConsignment.collectionDetails.address': getDefaultCollectionAddress,
  'outboundConsignment.collectionDetails.contactDetails':
    getDefaultCollectionContactDetails,
  'outboundConsignment.parcels': () => [
    {
      parcelId: crypto.randomUUID(),
    },
  ],
  'outboundConsignment.totalWeight': () => 1,
  'outboundConsignment.numberOfParcels': () => 1,
  'outboundConsignment.liabilityValue': () => 50,
  'outboundConsignment.shippingRef1': BasketSelectors.getBasketId,
  _valid: () => false,
});
